/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DiplomaTypeEnum } from './DiplomaTypeEnum';
import {
    DiplomaTypeEnumFromJSON,
    DiplomaTypeEnumFromJSONTyped,
    DiplomaTypeEnumToJSON,
    DiplomaTypeEnumToJSONTyped,
} from './DiplomaTypeEnum';

/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface NotNestedDiploma
 */
export interface NotNestedDiploma {
    /**
     * 
     * @type {number}
     * @memberof NotNestedDiploma
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof NotNestedDiploma
     */
    readonly diploma_url: string;
    /**
     * Type de diplôme
     * 
     * * `619` - AFPS
     * * `73` - BAFA
     * * `140` - BAFD
     * * `618` - BP JEPS
     * * `615` - Diplome Marin
     * * `1000` - Équivalence BAFD
     * * `632` - DE Educateur Spécialisé 
     * * `626` - DE JEPS
     * * `603` - PSC1 Prevention et Secours Civiques de niveau 1
     * * `608` - Surveillant de Baignade
     * * `1001` - Autre Diplome ou Brevet
     * @type {DiplomaTypeEnum}
     * @memberof NotNestedDiploma
     */
    diploma_type: DiplomaTypeEnum;
    /**
     * Date d'obtention du diplome
     * @type {Date}
     * @memberof NotNestedDiploma
     */
    issue_date: Date;
    /**
     * 
     * @type {boolean}
     * @memberof NotNestedDiploma
     */
    readonly diploma_available: boolean;
}



/**
 * Check if a given object implements the NotNestedDiploma interface.
 */
export function instanceOfNotNestedDiploma(value: object): value is NotNestedDiploma {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('diploma_url' in value) || value['diploma_url'] === undefined) return false;
    if (!('diploma_type' in value) || value['diploma_type'] === undefined) return false;
    if (!('issue_date' in value) || value['issue_date'] === undefined) return false;
    if (!('diploma_available' in value) || value['diploma_available'] === undefined) return false;
    return true;
}

export function NotNestedDiplomaFromJSON(json: any): NotNestedDiploma {
    return NotNestedDiplomaFromJSONTyped(json, false);
}

export function NotNestedDiplomaFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotNestedDiploma {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'diploma_url': json['diploma_url'],
        'diploma_type': DiplomaTypeEnumFromJSON(json['diploma_type']),
        'issue_date': (new Date(json['issue_date'])),
        'diploma_available': json['diploma_available'],
    };
}

export function NotNestedDiplomaToJSON(json: any): NotNestedDiploma {
    return NotNestedDiplomaToJSONTyped(json, false);
}

export function NotNestedDiplomaToJSONTyped(value?: Omit<NotNestedDiploma, 'id'|'diploma_url'|'diploma_available'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'diploma_type': DiplomaTypeEnumToJSON(value['diploma_type']),
        'issue_date': ((value['issue_date']).toISOString().substring(0,10)),
    };
}

