/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PasswordResetConfirmRetype
 */
export interface PasswordResetConfirmRetype {
    /**
     * 
     * @type {string}
     * @memberof PasswordResetConfirmRetype
     */
    uid: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordResetConfirmRetype
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordResetConfirmRetype
     */
    new_password: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordResetConfirmRetype
     */
    re_new_password: string;
}

/**
 * Check if a given object implements the PasswordResetConfirmRetype interface.
 */
export function instanceOfPasswordResetConfirmRetype(value: object): value is PasswordResetConfirmRetype {
    if (!('uid' in value) || value['uid'] === undefined) return false;
    if (!('token' in value) || value['token'] === undefined) return false;
    if (!('new_password' in value) || value['new_password'] === undefined) return false;
    if (!('re_new_password' in value) || value['re_new_password'] === undefined) return false;
    return true;
}

export function PasswordResetConfirmRetypeFromJSON(json: any): PasswordResetConfirmRetype {
    return PasswordResetConfirmRetypeFromJSONTyped(json, false);
}

export function PasswordResetConfirmRetypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PasswordResetConfirmRetype {
    if (json == null) {
        return json;
    }
    return {
        
        'uid': json['uid'],
        'token': json['token'],
        'new_password': json['new_password'],
        're_new_password': json['re_new_password'],
    };
}

export function PasswordResetConfirmRetypeToJSON(json: any): PasswordResetConfirmRetype {
    return PasswordResetConfirmRetypeToJSONTyped(json, false);
}

export function PasswordResetConfirmRetypeToJSONTyped(value?: PasswordResetConfirmRetype | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'uid': value['uid'],
        'token': value['token'],
        'new_password': value['new_password'],
        're_new_password': value['re_new_password'],
    };
}

