/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProviderAuth
 */
export interface ProviderAuth {
    /**
     * 
     * @type {string}
     * @memberof ProviderAuth
     */
    readonly access: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderAuth
     */
    readonly refresh: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderAuth
     */
    readonly user: string;
}

/**
 * Check if a given object implements the ProviderAuth interface.
 */
export function instanceOfProviderAuth(value: object): value is ProviderAuth {
    if (!('access' in value) || value['access'] === undefined) return false;
    if (!('refresh' in value) || value['refresh'] === undefined) return false;
    if (!('user' in value) || value['user'] === undefined) return false;
    return true;
}

export function ProviderAuthFromJSON(json: any): ProviderAuth {
    return ProviderAuthFromJSONTyped(json, false);
}

export function ProviderAuthFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProviderAuth {
    if (json == null) {
        return json;
    }
    return {
        
        'access': json['access'],
        'refresh': json['refresh'],
        'user': json['user'],
    };
}

export function ProviderAuthToJSON(json: any): ProviderAuth {
    return ProviderAuthToJSONTyped(json, false);
}

export function ProviderAuthToJSONTyped(value?: Omit<ProviderAuth, 'access'|'refresh'|'user'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
    };
}

