/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface MeetingFormField
 */
export interface MeetingFormField {
    /**
     * 
     * @type {string}
     * @memberof MeetingFormField
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingFormField
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingFormField
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingFormField
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingFormField
     */
    choices?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingFormField
     */
    help_info?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingFormField
     */
    default_value?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MeetingFormField
     */
    is_required?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MeetingFormField
     */
    order: number;
}

/**
 * Check if a given object implements the MeetingFormField interface.
 */
export function instanceOfMeetingFormField(value: object): value is MeetingFormField {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('order' in value) || value['order'] === undefined) return false;
    return true;
}

export function MeetingFormFieldFromJSON(json: any): MeetingFormField {
    return MeetingFormFieldFromJSONTyped(json, false);
}

export function MeetingFormFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeetingFormField {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'name': json['name'],
        'type': json['type'],
        'choices': json['choices'] == null ? undefined : json['choices'],
        'help_info': json['help_info'] == null ? undefined : json['help_info'],
        'default_value': json['default_value'] == null ? undefined : json['default_value'],
        'is_required': json['is_required'] == null ? undefined : json['is_required'],
        'order': json['order'],
    };
}

export function MeetingFormFieldToJSON(json: any): MeetingFormField {
    return MeetingFormFieldToJSONTyped(json, false);
}

export function MeetingFormFieldToJSONTyped(value?: Omit<MeetingFormField, 'id'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'title': value['title'],
        'name': value['name'],
        'type': value['type'],
        'choices': value['choices'],
        'help_info': value['help_info'],
        'default_value': value['default_value'],
        'is_required': value['is_required'],
        'order': value['order'],
    };
}

