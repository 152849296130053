/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface MeetingModule
 */
export interface MeetingModule {
    /**
     * 
     * @type {string}
     * @memberof MeetingModule
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingModule
     */
    readonly codename: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingModule
     */
    readonly label: string | null;
}

/**
 * Check if a given object implements the MeetingModule interface.
 */
export function instanceOfMeetingModule(value: object): value is MeetingModule {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('codename' in value) || value['codename'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    return true;
}

export function MeetingModuleFromJSON(json: any): MeetingModule {
    return MeetingModuleFromJSONTyped(json, false);
}

export function MeetingModuleFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeetingModule {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'codename': json['codename'],
        'label': json['label'],
    };
}

export function MeetingModuleToJSON(json: any): MeetingModule {
    return MeetingModuleToJSONTyped(json, false);
}

export function MeetingModuleToJSONTyped(value?: Omit<MeetingModule, 'id'|'codename'|'label'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
    };
}

