/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StatusEnum } from './StatusEnum';
import {
    StatusEnumFromJSON,
    StatusEnumFromJSONTyped,
    StatusEnumToJSON,
    StatusEnumToJSONTyped,
} from './StatusEnum';
import type { MeetingModule } from './MeetingModule';
import {
    MeetingModuleFromJSON,
    MeetingModuleFromJSONTyped,
    MeetingModuleToJSON,
    MeetingModuleToJSONTyped,
} from './MeetingModule';
import type { NotNestedPublicMeetingParticipant } from './NotNestedPublicMeetingParticipant';
import {
    NotNestedPublicMeetingParticipantFromJSON,
    NotNestedPublicMeetingParticipantFromJSONTyped,
    NotNestedPublicMeetingParticipantToJSON,
    NotNestedPublicMeetingParticipantToJSONTyped,
} from './NotNestedPublicMeetingParticipant';
import type { OpinionStatusEnum } from './OpinionStatusEnum';
import {
    OpinionStatusEnumFromJSON,
    OpinionStatusEnumFromJSONTyped,
    OpinionStatusEnumToJSON,
    OpinionStatusEnumToJSONTyped,
} from './OpinionStatusEnum';
import type { TagadaStatusEnum } from './TagadaStatusEnum';
import {
    TagadaStatusEnumFromJSON,
    TagadaStatusEnumFromJSONTyped,
    TagadaStatusEnumToJSON,
    TagadaStatusEnumToJSONTyped,
} from './TagadaStatusEnum';
import type { MeetingType } from './MeetingType';
import {
    MeetingTypeFromJSON,
    MeetingTypeFromJSONTyped,
    MeetingTypeToJSON,
    MeetingTypeToJSONTyped,
} from './MeetingType';
import type { StructureType } from './StructureType';
import {
    StructureTypeFromJSON,
    StructureTypeFromJSONTyped,
    StructureTypeToJSON,
    StructureTypeToJSONTyped,
} from './StructureType';
import type { NotNestedPlace } from './NotNestedPlace';
import {
    NotNestedPlaceFromJSON,
    NotNestedPlaceFromJSONTyped,
    NotNestedPlaceToJSON,
    NotNestedPlaceToJSONTyped,
} from './NotNestedPlace';
import type { Track } from './Track';
import {
    TrackFromJSON,
    TrackFromJSONTyped,
    TrackToJSON,
    TrackToJSONTyped,
} from './Track';

/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface Meeting
 */
export interface Meeting {
    /**
     * 
     * @type {string}
     * @memberof Meeting
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof Meeting
     */
    readonly label: string;
    /**
     * 
     * @type {Date}
     * @memberof Meeting
     */
    readonly created_at: Date;
    /**
     * 
     * @type {Date}
     * @memberof Meeting
     */
    readonly end_date: Date;
    /**
     * 
     * @type {MeetingType}
     * @memberof Meeting
     */
    readonly meeting_type: MeetingType;
    /**
     * 
     * @type {string}
     * @memberof Meeting
     */
    readonly meeting_type_id: string | null;
    /**
     * 
     * @type {NotNestedPlace}
     * @memberof Meeting
     */
    readonly place: NotNestedPlace;
    /**
     * 
     * @type {string}
     * @memberof Meeting
     */
    readonly place_id: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Meeting
     */
    readonly start_date: Date;
    /**
     * 
     * @type {StructureType}
     * @memberof Meeting
     */
    readonly structure_organisatrice: StructureType;
    /**
     * 
     * @type {string}
     * @memberof Meeting
     */
    readonly structure_organisatrice_id: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Meeting
     */
    readonly updated_at: Date;
    /**
     * 
     * @type {Array<MeetingModule>}
     * @memberof Meeting
     */
    modules: Array<MeetingModule>;
    /**
     * 
     * @type {string}
     * @memberof Meeting
     */
    readonly daytime_min: string;
    /**
     * 
     * @type {string}
     * @memberof Meeting
     */
    readonly daytime_max: string;
    /**
     * 
     * @type {number}
     * @memberof Meeting
     */
    readonly ndays: number;
    /**
     * 
     * @type {string}
     * @memberof Meeting
     */
    code_analytic?: string;
    /**
     * 
     * @type {string}
     * @memberof Meeting
     */
    readonly intranet_id: string;
    /**
     * 
     * @type {Array<NotNestedPublicMeetingParticipant>}
     * @memberof Meeting
     */
    readonly supervisors: Array<NotNestedPublicMeetingParticipant>;
    /**
     * 
     * @type {string}
     * @memberof Meeting
     */
    num_agreement_js?: string;
    /**
     * Description du stage
     * @type {string}
     * @memberof Meeting
     */
    description?: string;
    /**
     * 
     * @type {StatusEnum}
     * @memberof Meeting
     */
    readonly status: StatusEnum;
    /**
     * 
     * @type {Array<Track>}
     * @memberof Meeting
     */
    readonly tracks: Array<Track>;
    /**
     * 
     * @type {string}
     * @memberof Meeting
     */
    tagada_password?: string | null;
    /**
     * 
     * @type {TagadaStatusEnum}
     * @memberof Meeting
     */
    readonly tagada_status: TagadaStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof Meeting
     */
    readonly tagada_participants_last_sync: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof Meeting
     */
    readonly tagada_avis_last_sync: Date | null;
    /**
     * 
     * @type {number}
     * @memberof Meeting
     */
    readonly tagada_total_candidates: number;
    /**
     * 
     * @type {number}
     * @memberof Meeting
     */
    readonly tagada_synced_candidates: number;
    /**
     * 
     * @type {Date}
     * @memberof Meeting
     */
    readonly tagada_participants_sync_lock: Date | null;
    /**
     * 
     * @type {OpinionStatusEnum}
     * @memberof Meeting
     */
    opinion_status?: OpinionStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Meeting
     */
    readonly has_opinions: boolean;
}



/**
 * Check if a given object implements the Meeting interface.
 */
export function instanceOfMeeting(value: object): value is Meeting {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('created_at' in value) || value['created_at'] === undefined) return false;
    if (!('end_date' in value) || value['end_date'] === undefined) return false;
    if (!('meeting_type' in value) || value['meeting_type'] === undefined) return false;
    if (!('meeting_type_id' in value) || value['meeting_type_id'] === undefined) return false;
    if (!('place' in value) || value['place'] === undefined) return false;
    if (!('place_id' in value) || value['place_id'] === undefined) return false;
    if (!('start_date' in value) || value['start_date'] === undefined) return false;
    if (!('structure_organisatrice' in value) || value['structure_organisatrice'] === undefined) return false;
    if (!('structure_organisatrice_id' in value) || value['structure_organisatrice_id'] === undefined) return false;
    if (!('updated_at' in value) || value['updated_at'] === undefined) return false;
    if (!('modules' in value) || value['modules'] === undefined) return false;
    if (!('daytime_min' in value) || value['daytime_min'] === undefined) return false;
    if (!('daytime_max' in value) || value['daytime_max'] === undefined) return false;
    if (!('ndays' in value) || value['ndays'] === undefined) return false;
    if (!('intranet_id' in value) || value['intranet_id'] === undefined) return false;
    if (!('supervisors' in value) || value['supervisors'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('tracks' in value) || value['tracks'] === undefined) return false;
    if (!('tagada_status' in value) || value['tagada_status'] === undefined) return false;
    if (!('tagada_participants_last_sync' in value) || value['tagada_participants_last_sync'] === undefined) return false;
    if (!('tagada_avis_last_sync' in value) || value['tagada_avis_last_sync'] === undefined) return false;
    if (!('tagada_total_candidates' in value) || value['tagada_total_candidates'] === undefined) return false;
    if (!('tagada_synced_candidates' in value) || value['tagada_synced_candidates'] === undefined) return false;
    if (!('tagada_participants_sync_lock' in value) || value['tagada_participants_sync_lock'] === undefined) return false;
    if (!('has_opinions' in value) || value['has_opinions'] === undefined) return false;
    return true;
}

export function MeetingFromJSON(json: any): Meeting {
    return MeetingFromJSONTyped(json, false);
}

export function MeetingFromJSONTyped(json: any, ignoreDiscriminator: boolean): Meeting {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'label': json['label'],
        'created_at': (new Date(json['created_at'])),
        'end_date': (new Date(json['end_date'])),
        'meeting_type': MeetingTypeFromJSON(json['meeting_type']),
        'meeting_type_id': json['meeting_type_id'],
        'place': NotNestedPlaceFromJSON(json['place']),
        'place_id': json['place_id'],
        'start_date': (new Date(json['start_date'])),
        'structure_organisatrice': StructureTypeFromJSON(json['structure_organisatrice']),
        'structure_organisatrice_id': json['structure_organisatrice_id'],
        'updated_at': (new Date(json['updated_at'])),
        'modules': ((json['modules'] as Array<any>).map(MeetingModuleFromJSON)),
        'daytime_min': json['daytime_min'],
        'daytime_max': json['daytime_max'],
        'ndays': json['ndays'],
        'code_analytic': json['code_analytic'] == null ? undefined : json['code_analytic'],
        'intranet_id': json['intranet_id'],
        'supervisors': ((json['supervisors'] as Array<any>).map(NotNestedPublicMeetingParticipantFromJSON)),
        'num_agreement_js': json['num_agreement_js'] == null ? undefined : json['num_agreement_js'],
        'description': json['description'] == null ? undefined : json['description'],
        'status': StatusEnumFromJSON(json['status']),
        'tracks': ((json['tracks'] as Array<any>).map(TrackFromJSON)),
        'tagada_password': json['tagada_password'] == null ? undefined : json['tagada_password'],
        'tagada_status': TagadaStatusEnumFromJSON(json['tagada_status']),
        'tagada_participants_last_sync': (json['tagada_participants_last_sync'] == null ? null : new Date(json['tagada_participants_last_sync'])),
        'tagada_avis_last_sync': (json['tagada_avis_last_sync'] == null ? null : new Date(json['tagada_avis_last_sync'])),
        'tagada_total_candidates': json['tagada_total_candidates'],
        'tagada_synced_candidates': json['tagada_synced_candidates'],
        'tagada_participants_sync_lock': (json['tagada_participants_sync_lock'] == null ? null : new Date(json['tagada_participants_sync_lock'])),
        'opinion_status': json['opinion_status'] == null ? undefined : OpinionStatusEnumFromJSON(json['opinion_status']),
        'has_opinions': json['has_opinions'],
    };
}

export function MeetingToJSON(json: any): Meeting {
    return MeetingToJSONTyped(json, false);
}

export function MeetingToJSONTyped(value?: Omit<Meeting, 'id'|'label'|'created_at'|'end_date'|'meeting_type'|'meeting_type_id'|'place'|'place_id'|'start_date'|'structure_organisatrice'|'structure_organisatrice_id'|'updated_at'|'daytime_min'|'daytime_max'|'ndays'|'intranet_id'|'supervisors'|'status'|'tracks'|'tagada_status'|'tagada_participants_last_sync'|'tagada_avis_last_sync'|'tagada_total_candidates'|'tagada_synced_candidates'|'tagada_participants_sync_lock'|'has_opinions'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'modules': ((value['modules'] as Array<any>).map(MeetingModuleToJSON)),
        'code_analytic': value['code_analytic'],
        'num_agreement_js': value['num_agreement_js'],
        'description': value['description'],
        'tagada_password': value['tagada_password'],
        'opinion_status': OpinionStatusEnumToJSON(value['opinion_status']),
    };
}

