/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface NotNestedPublicMeetingParticipant
 */
export interface NotNestedPublicMeetingParticipant {
    /**
     * 
     * @type {string}
     * @memberof NotNestedPublicMeetingParticipant
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof NotNestedPublicMeetingParticipant
     */
    readonly user_id: string;
    /**
     * 
     * @type {string}
     * @memberof NotNestedPublicMeetingParticipant
     */
    readonly first_name: string;
    /**
     * 
     * @type {string}
     * @memberof NotNestedPublicMeetingParticipant
     */
    readonly last_name: string;
    /**
     * 
     * @type {string}
     * @memberof NotNestedPublicMeetingParticipant
     */
    readonly usage_first_name: string;
    /**
     * 
     * @type {string}
     * @memberof NotNestedPublicMeetingParticipant
     */
    readonly usage_last_name: string;
}

/**
 * Check if a given object implements the NotNestedPublicMeetingParticipant interface.
 */
export function instanceOfNotNestedPublicMeetingParticipant(value: object): value is NotNestedPublicMeetingParticipant {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('user_id' in value) || value['user_id'] === undefined) return false;
    if (!('first_name' in value) || value['first_name'] === undefined) return false;
    if (!('last_name' in value) || value['last_name'] === undefined) return false;
    if (!('usage_first_name' in value) || value['usage_first_name'] === undefined) return false;
    if (!('usage_last_name' in value) || value['usage_last_name'] === undefined) return false;
    return true;
}

export function NotNestedPublicMeetingParticipantFromJSON(json: any): NotNestedPublicMeetingParticipant {
    return NotNestedPublicMeetingParticipantFromJSONTyped(json, false);
}

export function NotNestedPublicMeetingParticipantFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotNestedPublicMeetingParticipant {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'user_id': json['user_id'],
        'first_name': json['first_name'],
        'last_name': json['last_name'],
        'usage_first_name': json['usage_first_name'],
        'usage_last_name': json['usage_last_name'],
    };
}

export function NotNestedPublicMeetingParticipantToJSON(json: any): NotNestedPublicMeetingParticipant {
    return NotNestedPublicMeetingParticipantToJSONTyped(json, false);
}

export function NotNestedPublicMeetingParticipantToJSONTyped(value?: Omit<NotNestedPublicMeetingParticipant, 'id'|'user_id'|'first_name'|'last_name'|'usage_first_name'|'usage_last_name'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
    };
}

