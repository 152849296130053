/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `0` - Stagiaire
 * * `1` - Formateur
 * * `2` - Directeur
 * * `3` - Accompagnant
 * * `4` - Intervenant
 * @export
 */
export const IntranetIdEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
} as const;
export type IntranetIdEnum = typeof IntranetIdEnum[keyof typeof IntranetIdEnum];


export function instanceOfIntranetIdEnum(value: any): boolean {
    for (const key in IntranetIdEnum) {
        if (Object.prototype.hasOwnProperty.call(IntranetIdEnum, key)) {
            if (IntranetIdEnum[key as keyof typeof IntranetIdEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function IntranetIdEnumFromJSON(json: any): IntranetIdEnum {
    return IntranetIdEnumFromJSONTyped(json, false);
}

export function IntranetIdEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntranetIdEnum {
    return json as IntranetIdEnum;
}

export function IntranetIdEnumToJSON(value?: IntranetIdEnum | null): any {
    return value as any;
}

export function IntranetIdEnumToJSONTyped(value: any, ignoreDiscriminator: boolean): IntranetIdEnum {
    return value as IntranetIdEnum;
}

