/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface DailyTask
 */
export interface DailyTask {
    /**
     * 
     * @type {string}
     * @memberof DailyTask
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof DailyTask
     */
    description?: string;
    /**
     * Nom du service
     * @type {string}
     * @memberof DailyTask
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof DailyTask
     */
    readonly meeting: string;
}

/**
 * Check if a given object implements the DailyTask interface.
 */
export function instanceOfDailyTask(value: object): value is DailyTask {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('meeting' in value) || value['meeting'] === undefined) return false;
    return true;
}

export function DailyTaskFromJSON(json: any): DailyTask {
    return DailyTaskFromJSONTyped(json, false);
}

export function DailyTaskFromJSONTyped(json: any, ignoreDiscriminator: boolean): DailyTask {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'description': json['description'] == null ? undefined : json['description'],
        'label': json['label'],
        'meeting': json['meeting'],
    };
}

export function DailyTaskToJSON(json: any): DailyTask {
    return DailyTaskToJSONTyped(json, false);
}

export function DailyTaskToJSONTyped(value?: Omit<DailyTask, 'id'|'meeting'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'description': value['description'],
        'label': value['label'],
    };
}

