/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NotNestedDailyTaskTeam } from './NotNestedDailyTaskTeam';
import {
    NotNestedDailyTaskTeamFromJSON,
    NotNestedDailyTaskTeamFromJSONTyped,
    NotNestedDailyTaskTeamToJSON,
    NotNestedDailyTaskTeamToJSONTyped,
} from './NotNestedDailyTaskTeam';
import type { NotNestedTeam } from './NotNestedTeam';
import {
    NotNestedTeamFromJSON,
    NotNestedTeamFromJSONTyped,
    NotNestedTeamToJSON,
    NotNestedTeamToJSONTyped,
} from './NotNestedTeam';
import type { NotNestedMeetingRole } from './NotNestedMeetingRole';
import {
    NotNestedMeetingRoleFromJSON,
    NotNestedMeetingRoleFromJSONTyped,
    NotNestedMeetingRoleToJSON,
    NotNestedMeetingRoleToJSONTyped,
} from './NotNestedMeetingRole';
import type { Track } from './Track';
import {
    TrackFromJSON,
    TrackFromJSONTyped,
    TrackToJSON,
    TrackToJSONTyped,
} from './Track';
import type { BrancheEnum } from './BrancheEnum';
import {
    BrancheEnumFromJSON,
    BrancheEnumFromJSONTyped,
    BrancheEnumToJSON,
    BrancheEnumToJSONTyped,
} from './BrancheEnum';

/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface OwnMeetingParticipant
 */
export interface OwnMeetingParticipant {
    /**
     * 
     * @type {string}
     * @memberof OwnMeetingParticipant
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof OwnMeetingParticipant
     */
    readonly user_id: string;
    /**
     * 
     * @type {string}
     * @memberof OwnMeetingParticipant
     */
    readonly first_name: string;
    /**
     * 
     * @type {string}
     * @memberof OwnMeetingParticipant
     */
    readonly last_name: string;
    /**
     * 
     * @type {string}
     * @memberof OwnMeetingParticipant
     */
    readonly usage_first_name: string;
    /**
     * 
     * @type {string}
     * @memberof OwnMeetingParticipant
     */
    readonly usage_last_name: string;
    /**
     * 
     * @type {string}
     * @memberof OwnMeetingParticipant
     */
    readonly meeting: string;
    /**
     * 
     * @type {NotNestedMeetingRole}
     * @memberof OwnMeetingParticipant
     */
    readonly role: NotNestedMeetingRole;
    /**
     * 
     * @type {Array<NotNestedTeam>}
     * @memberof OwnMeetingParticipant
     */
    readonly teams: Array<NotNestedTeam>;
    /**
     * 
     * @type {NotNestedDailyTaskTeam}
     * @memberof OwnMeetingParticipant
     */
    readonly daily_task_team: NotNestedDailyTaskTeam;
    /**
     * Is imported from intranet the 1st time but not synchronized after
     * @type {string}
     * @memberof OwnMeetingParticipant
     */
    readonly food_regime: string;
    /**
     * Is imported from intranet the 1st time but not synchronized after
     * @type {string}
     * @memberof OwnMeetingParticipant
     */
    readonly num_bafa_bafd: string;
    /**
     * 
     * @type {string}
     * @memberof OwnMeetingParticipant
     */
    readonly hebergement: string;
    /**
     * 
     * @type {string}
     * @memberof OwnMeetingParticipant
     */
    readonly birth_name: string;
    /**
     * 
     * @type {string}
     * @memberof OwnMeetingParticipant
     */
    which_administrative_lastname: string;
    /**
     * 
     * @type {string}
     * @memberof OwnMeetingParticipant
     */
    which_administrative_firstname: string;
    /**
     * 
     * @type {string}
     * @memberof OwnMeetingParticipant
     */
    phone: string;
    /**
     * 
     * @type {Date}
     * @memberof OwnMeetingParticipant
     */
    birthdate?: Date;
    /**
     * 
     * @type {string}
     * @memberof OwnMeetingParticipant
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof OwnMeetingParticipant
     */
    gender: string;
    /**
     * Is imported from intranet the 1st time but not synchronized after
     * 
     * * `0` - Non-défini
     * * `270` - Farfadets
     * * `210` - Louveteaux-Jeannettes
     * * `220` - Scouts-Guides
     * * `230` - Pionniers-Caravelles
     * * `240` - Compagnons
     * * `290` - Audace
     * * `180` - Impeesa
     * * `310` - Équipe Groupe
     * * `300` - Responsable Groupe
     * * `600` - Équipe Territorial
     * * `505` - Responsable Territorial
     * * `500` - Délégué Territorial
     * * `900` - Équipe National
     * * `980` - Salarié
     * * `1000` - Autre branche
     * @type {BrancheEnum}
     * @memberof OwnMeetingParticipant
     */
    readonly branche: BrancheEnum;
    /**
     * 
     * @type {Track}
     * @memberof OwnMeetingParticipant
     */
    readonly track: Track;
    /**
     * Parcours de formation sélectionné. Is imported from intranet the 1st time but not synchronized after
     * @type {string}
     * @memberof OwnMeetingParticipant
     */
    readonly track_id: string | null;
    /**
     * 
     * @type {string}
     * @memberof OwnMeetingParticipant
     */
    readonly avatar_url: string;
    /**
     * 
     * @type {boolean}
     * @memberof OwnMeetingParticipant
     */
    readonly avatar_available: boolean;
    /**
     * 
     * @type {string}
     * @memberof OwnMeetingParticipant
     */
    readonly user_avatar_url: string;
    /**
     * 
     * @type {boolean}
     * @memberof OwnMeetingParticipant
     */
    readonly user_avatar_available: boolean;
    /**
     * 
     * @type {string}
     * @memberof OwnMeetingParticipant
     */
    readonly signature_url: string;
    /**
     * 
     * @type {boolean}
     * @memberof OwnMeetingParticipant
     */
    readonly signature_available: boolean;
    /**
     * 
     * @type {Date}
     * @memberof OwnMeetingParticipant
     */
    readonly last_sync: Date | null;
}



/**
 * Check if a given object implements the OwnMeetingParticipant interface.
 */
export function instanceOfOwnMeetingParticipant(value: object): value is OwnMeetingParticipant {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('user_id' in value) || value['user_id'] === undefined) return false;
    if (!('first_name' in value) || value['first_name'] === undefined) return false;
    if (!('last_name' in value) || value['last_name'] === undefined) return false;
    if (!('usage_first_name' in value) || value['usage_first_name'] === undefined) return false;
    if (!('usage_last_name' in value) || value['usage_last_name'] === undefined) return false;
    if (!('meeting' in value) || value['meeting'] === undefined) return false;
    if (!('role' in value) || value['role'] === undefined) return false;
    if (!('teams' in value) || value['teams'] === undefined) return false;
    if (!('daily_task_team' in value) || value['daily_task_team'] === undefined) return false;
    if (!('food_regime' in value) || value['food_regime'] === undefined) return false;
    if (!('num_bafa_bafd' in value) || value['num_bafa_bafd'] === undefined) return false;
    if (!('hebergement' in value) || value['hebergement'] === undefined) return false;
    if (!('birth_name' in value) || value['birth_name'] === undefined) return false;
    if (!('which_administrative_lastname' in value) || value['which_administrative_lastname'] === undefined) return false;
    if (!('which_administrative_firstname' in value) || value['which_administrative_firstname'] === undefined) return false;
    if (!('phone' in value) || value['phone'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('gender' in value) || value['gender'] === undefined) return false;
    if (!('branche' in value) || value['branche'] === undefined) return false;
    if (!('track' in value) || value['track'] === undefined) return false;
    if (!('track_id' in value) || value['track_id'] === undefined) return false;
    if (!('avatar_url' in value) || value['avatar_url'] === undefined) return false;
    if (!('avatar_available' in value) || value['avatar_available'] === undefined) return false;
    if (!('user_avatar_url' in value) || value['user_avatar_url'] === undefined) return false;
    if (!('user_avatar_available' in value) || value['user_avatar_available'] === undefined) return false;
    if (!('signature_url' in value) || value['signature_url'] === undefined) return false;
    if (!('signature_available' in value) || value['signature_available'] === undefined) return false;
    if (!('last_sync' in value) || value['last_sync'] === undefined) return false;
    return true;
}

export function OwnMeetingParticipantFromJSON(json: any): OwnMeetingParticipant {
    return OwnMeetingParticipantFromJSONTyped(json, false);
}

export function OwnMeetingParticipantFromJSONTyped(json: any, ignoreDiscriminator: boolean): OwnMeetingParticipant {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'user_id': json['user_id'],
        'first_name': json['first_name'],
        'last_name': json['last_name'],
        'usage_first_name': json['usage_first_name'],
        'usage_last_name': json['usage_last_name'],
        'meeting': json['meeting'],
        'role': NotNestedMeetingRoleFromJSON(json['role']),
        'teams': ((json['teams'] as Array<any>).map(NotNestedTeamFromJSON)),
        'daily_task_team': NotNestedDailyTaskTeamFromJSON(json['daily_task_team']),
        'food_regime': json['food_regime'],
        'num_bafa_bafd': json['num_bafa_bafd'],
        'hebergement': json['hebergement'],
        'birth_name': json['birth_name'],
        'which_administrative_lastname': json['which_administrative_lastname'],
        'which_administrative_firstname': json['which_administrative_firstname'],
        'phone': json['phone'],
        'birthdate': json['birthdate'] == null ? undefined : (new Date(json['birthdate'])),
        'email': json['email'],
        'gender': json['gender'],
        'branche': BrancheEnumFromJSON(json['branche']),
        'track': TrackFromJSON(json['track']),
        'track_id': json['track_id'],
        'avatar_url': json['avatar_url'],
        'avatar_available': json['avatar_available'],
        'user_avatar_url': json['user_avatar_url'],
        'user_avatar_available': json['user_avatar_available'],
        'signature_url': json['signature_url'],
        'signature_available': json['signature_available'],
        'last_sync': (json['last_sync'] == null ? null : new Date(json['last_sync'])),
    };
}

export function OwnMeetingParticipantToJSON(json: any): OwnMeetingParticipant {
    return OwnMeetingParticipantToJSONTyped(json, false);
}

export function OwnMeetingParticipantToJSONTyped(value?: Omit<OwnMeetingParticipant, 'id'|'user_id'|'first_name'|'last_name'|'usage_first_name'|'usage_last_name'|'meeting'|'role'|'teams'|'daily_task_team'|'food_regime'|'num_bafa_bafd'|'hebergement'|'birth_name'|'branche'|'track'|'track_id'|'avatar_url'|'avatar_available'|'user_avatar_url'|'user_avatar_available'|'signature_url'|'signature_available'|'last_sync'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'which_administrative_lastname': value['which_administrative_lastname'],
        'which_administrative_firstname': value['which_administrative_firstname'],
        'phone': value['phone'],
        'birthdate': value['birthdate'] == null ? undefined : ((value['birthdate']).toISOString().substring(0,10)),
        'email': value['email'],
        'gender': value['gender'],
    };
}

