/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserAccessRights
 */
export interface UserAccessRights {
    /**
     * 
     * @type {string}
     * @memberof UserAccessRights
     */
    user_id: string;
    /**
     * 
     * @type {string}
     * @memberof UserAccessRights
     */
    meeting_id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserAccessRights
     */
    access_rights: Array<string>;
}

/**
 * Check if a given object implements the UserAccessRights interface.
 */
export function instanceOfUserAccessRights(value: object): value is UserAccessRights {
    if (!('user_id' in value) || value['user_id'] === undefined) return false;
    if (!('access_rights' in value) || value['access_rights'] === undefined) return false;
    return true;
}

export function UserAccessRightsFromJSON(json: any): UserAccessRights {
    return UserAccessRightsFromJSONTyped(json, false);
}

export function UserAccessRightsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAccessRights {
    if (json == null) {
        return json;
    }
    return {
        
        'user_id': json['user_id'],
        'meeting_id': json['meeting_id'] == null ? undefined : json['meeting_id'],
        'access_rights': json['access_rights'],
    };
}

export function UserAccessRightsToJSON(json: any): UserAccessRights {
    return UserAccessRightsToJSONTyped(json, false);
}

export function UserAccessRightsToJSONTyped(value?: UserAccessRights | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'user_id': value['user_id'],
        'meeting_id': value['meeting_id'],
        'access_rights': value['access_rights'],
    };
}

