/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface PatchedDailyTask
 */
export interface PatchedDailyTask {
    /**
     * 
     * @type {string}
     * @memberof PatchedDailyTask
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedDailyTask
     */
    description?: string;
    /**
     * Nom du service
     * @type {string}
     * @memberof PatchedDailyTask
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedDailyTask
     */
    readonly meeting?: string;
}

/**
 * Check if a given object implements the PatchedDailyTask interface.
 */
export function instanceOfPatchedDailyTask(value: object): value is PatchedDailyTask {
    return true;
}

export function PatchedDailyTaskFromJSON(json: any): PatchedDailyTask {
    return PatchedDailyTaskFromJSONTyped(json, false);
}

export function PatchedDailyTaskFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedDailyTask {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'description': json['description'] == null ? undefined : json['description'],
        'label': json['label'] == null ? undefined : json['label'],
        'meeting': json['meeting'] == null ? undefined : json['meeting'],
    };
}

export function PatchedDailyTaskToJSON(json: any): PatchedDailyTask {
    return PatchedDailyTaskToJSONTyped(json, false);
}

export function PatchedDailyTaskToJSONTyped(value?: Omit<PatchedDailyTask, 'id'|'meeting'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'description': value['description'],
        'label': value['label'],
    };
}

