/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface NotNestedDailyTaskTeam
 */
export interface NotNestedDailyTaskTeam {
    /**
     * 
     * @type {string}
     * @memberof NotNestedDailyTaskTeam
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof NotNestedDailyTaskTeam
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof NotNestedDailyTaskTeam
     */
    color?: string;
    /**
     * The meeting associated to the team
     * @type {string}
     * @memberof NotNestedDailyTaskTeam
     */
    readonly meeting: string;
}

/**
 * Check if a given object implements the NotNestedDailyTaskTeam interface.
 */
export function instanceOfNotNestedDailyTaskTeam(value: object): value is NotNestedDailyTaskTeam {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('meeting' in value) || value['meeting'] === undefined) return false;
    return true;
}

export function NotNestedDailyTaskTeamFromJSON(json: any): NotNestedDailyTaskTeam {
    return NotNestedDailyTaskTeamFromJSONTyped(json, false);
}

export function NotNestedDailyTaskTeamFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotNestedDailyTaskTeam {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'label': json['label'],
        'color': json['color'] == null ? undefined : json['color'],
        'meeting': json['meeting'],
    };
}

export function NotNestedDailyTaskTeamToJSON(json: any): NotNestedDailyTaskTeam {
    return NotNestedDailyTaskTeamToJSONTyped(json, false);
}

export function NotNestedDailyTaskTeamToJSONTyped(value?: Omit<NotNestedDailyTaskTeam, 'id'|'meeting'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'label': value['label'],
        'color': value['color'],
    };
}

