/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NotNestedPublicMeetingParticipantWithAvatar } from './NotNestedPublicMeetingParticipantWithAvatar';
import {
    NotNestedPublicMeetingParticipantWithAvatarFromJSON,
    NotNestedPublicMeetingParticipantWithAvatarFromJSONTyped,
    NotNestedPublicMeetingParticipantWithAvatarToJSON,
    NotNestedPublicMeetingParticipantWithAvatarToJSONTyped,
} from './NotNestedPublicMeetingParticipantWithAvatar';

/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface DailyTaskTeam
 */
export interface DailyTaskTeam {
    /**
     * 
     * @type {string}
     * @memberof DailyTaskTeam
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof DailyTaskTeam
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof DailyTaskTeam
     */
    color?: string;
    /**
     * The meeting associated to the team
     * @type {string}
     * @memberof DailyTaskTeam
     */
    readonly meeting: string;
    /**
     * 
     * @type {Array<NotNestedPublicMeetingParticipantWithAvatar>}
     * @memberof DailyTaskTeam
     */
    participants: Array<NotNestedPublicMeetingParticipantWithAvatar>;
}

/**
 * Check if a given object implements the DailyTaskTeam interface.
 */
export function instanceOfDailyTaskTeam(value: object): value is DailyTaskTeam {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('meeting' in value) || value['meeting'] === undefined) return false;
    if (!('participants' in value) || value['participants'] === undefined) return false;
    return true;
}

export function DailyTaskTeamFromJSON(json: any): DailyTaskTeam {
    return DailyTaskTeamFromJSONTyped(json, false);
}

export function DailyTaskTeamFromJSONTyped(json: any, ignoreDiscriminator: boolean): DailyTaskTeam {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'label': json['label'],
        'color': json['color'] == null ? undefined : json['color'],
        'meeting': json['meeting'],
        'participants': ((json['participants'] as Array<any>).map(NotNestedPublicMeetingParticipantWithAvatarFromJSON)),
    };
}

export function DailyTaskTeamToJSON(json: any): DailyTaskTeam {
    return DailyTaskTeamToJSONTyped(json, false);
}

export function DailyTaskTeamToJSONTyped(value?: Omit<DailyTaskTeam, 'id'|'meeting'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'label': value['label'],
        'color': value['color'],
        'participants': ((value['participants'] as Array<any>).map(NotNestedPublicMeetingParticipantWithAvatarToJSON)),
    };
}

