/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ReadonlyStructure } from './ReadonlyStructure';
import {
    ReadonlyStructureFromJSON,
    ReadonlyStructureFromJSONTyped,
    ReadonlyStructureToJSON,
    ReadonlyStructureToJSONTyped,
} from './ReadonlyStructure';

/**
 * 
 * @export
 * @interface PaginatedReadonlyStructureList
 */
export interface PaginatedReadonlyStructureList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedReadonlyStructureList
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedReadonlyStructureList
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedReadonlyStructureList
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<ReadonlyStructure>}
     * @memberof PaginatedReadonlyStructureList
     */
    results: Array<ReadonlyStructure>;
}

/**
 * Check if a given object implements the PaginatedReadonlyStructureList interface.
 */
export function instanceOfPaginatedReadonlyStructureList(value: object): value is PaginatedReadonlyStructureList {
    if (!('count' in value) || value['count'] === undefined) return false;
    if (!('results' in value) || value['results'] === undefined) return false;
    return true;
}

export function PaginatedReadonlyStructureListFromJSON(json: any): PaginatedReadonlyStructureList {
    return PaginatedReadonlyStructureListFromJSONTyped(json, false);
}

export function PaginatedReadonlyStructureListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedReadonlyStructureList {
    if (json == null) {
        return json;
    }
    return {
        
        'count': json['count'],
        'next': json['next'] == null ? undefined : json['next'],
        'previous': json['previous'] == null ? undefined : json['previous'],
        'results': ((json['results'] as Array<any>).map(ReadonlyStructureFromJSON)),
    };
}

export function PaginatedReadonlyStructureListToJSON(json: any): PaginatedReadonlyStructureList {
    return PaginatedReadonlyStructureListToJSONTyped(json, false);
}

export function PaginatedReadonlyStructureListToJSONTyped(value?: PaginatedReadonlyStructureList | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'count': value['count'],
        'next': value['next'],
        'previous': value['previous'],
        'results': ((value['results'] as Array<any>).map(ReadonlyStructureToJSON)),
    };
}

