/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ParticipantList
 */
export interface ParticipantList {
    /**
     * 
     * @type {string}
     * @memberof ParticipantList
     */
    meeting_type: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantList
     */
    lastname: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantList
     */
    firstname: string;
    /**
     * 
     * @type {Date}
     * @memberof ParticipantList
     */
    birthdate: Date;
    /**
     * 
     * @type {string}
     * @memberof ParticipantList
     */
    fonction: string | null;
    /**
     * 
     * @type {string}
     * @memberof ParticipantList
     */
    structure_principale: string | null;
    /**
     * 
     * @type {string}
     * @memberof ParticipantList
     */
    groupe: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantList
     */
    territoire: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantList
     */
    choix_parcours: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantList
     */
    num_bafa_bafd: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantList
     */
    food_regime: string;
}

/**
 * Check if a given object implements the ParticipantList interface.
 */
export function instanceOfParticipantList(value: object): value is ParticipantList {
    if (!('meeting_type' in value) || value['meeting_type'] === undefined) return false;
    if (!('lastname' in value) || value['lastname'] === undefined) return false;
    if (!('firstname' in value) || value['firstname'] === undefined) return false;
    if (!('birthdate' in value) || value['birthdate'] === undefined) return false;
    if (!('fonction' in value) || value['fonction'] === undefined) return false;
    if (!('structure_principale' in value) || value['structure_principale'] === undefined) return false;
    if (!('groupe' in value) || value['groupe'] === undefined) return false;
    if (!('territoire' in value) || value['territoire'] === undefined) return false;
    if (!('choix_parcours' in value) || value['choix_parcours'] === undefined) return false;
    if (!('num_bafa_bafd' in value) || value['num_bafa_bafd'] === undefined) return false;
    if (!('food_regime' in value) || value['food_regime'] === undefined) return false;
    return true;
}

export function ParticipantListFromJSON(json: any): ParticipantList {
    return ParticipantListFromJSONTyped(json, false);
}

export function ParticipantListFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParticipantList {
    if (json == null) {
        return json;
    }
    return {
        
        'meeting_type': json['meeting_type'],
        'lastname': json['lastname'],
        'firstname': json['firstname'],
        'birthdate': (new Date(json['birthdate'])),
        'fonction': json['fonction'],
        'structure_principale': json['structure_principale'],
        'groupe': json['groupe'],
        'territoire': json['territoire'],
        'choix_parcours': json['choix_parcours'],
        'num_bafa_bafd': json['num_bafa_bafd'],
        'food_regime': json['food_regime'],
    };
}

export function ParticipantListToJSON(json: any): ParticipantList {
    return ParticipantListToJSONTyped(json, false);
}

export function ParticipantListToJSONTyped(value?: ParticipantList | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'meeting_type': value['meeting_type'],
        'lastname': value['lastname'],
        'firstname': value['firstname'],
        'birthdate': ((value['birthdate']).toISOString().substring(0,10)),
        'fonction': value['fonction'],
        'structure_principale': value['structure_principale'],
        'groupe': value['groupe'],
        'territoire': value['territoire'],
        'choix_parcours': value['choix_parcours'],
        'num_bafa_bafd': value['num_bafa_bafd'],
        'food_regime': value['food_regime'],
    };
}

