/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TokenObtainPairEmail
 */
export interface TokenObtainPairEmail {
    /**
     * 
     * @type {string}
     * @memberof TokenObtainPairEmail
     */
    username_or_email: string;
    /**
     * 
     * @type {string}
     * @memberof TokenObtainPairEmail
     */
    password: string;
}

/**
 * Check if a given object implements the TokenObtainPairEmail interface.
 */
export function instanceOfTokenObtainPairEmail(value: object): value is TokenObtainPairEmail {
    if (!('username_or_email' in value) || value['username_or_email'] === undefined) return false;
    if (!('password' in value) || value['password'] === undefined) return false;
    return true;
}

export function TokenObtainPairEmailFromJSON(json: any): TokenObtainPairEmail {
    return TokenObtainPairEmailFromJSONTyped(json, false);
}

export function TokenObtainPairEmailFromJSONTyped(json: any, ignoreDiscriminator: boolean): TokenObtainPairEmail {
    if (json == null) {
        return json;
    }
    return {
        
        'username_or_email': json['username_or_email'],
        'password': json['password'],
    };
}

export function TokenObtainPairEmailToJSON(json: any): TokenObtainPairEmail {
    return TokenObtainPairEmailToJSONTyped(json, false);
}

export function TokenObtainPairEmailToJSONTyped(value?: TokenObtainPairEmail | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'username_or_email': value['username_or_email'],
        'password': value['password'],
    };
}

