/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SetUsername
 */
export interface SetUsername {
    /**
     * 
     * @type {string}
     * @memberof SetUsername
     */
    current_password: string;
    /**
     * 
     * @type {string}
     * @memberof SetUsername
     */
    new_email?: string;
}

/**
 * Check if a given object implements the SetUsername interface.
 */
export function instanceOfSetUsername(value: object): value is SetUsername {
    if (!('current_password' in value) || value['current_password'] === undefined) return false;
    return true;
}

export function SetUsernameFromJSON(json: any): SetUsername {
    return SetUsernameFromJSONTyped(json, false);
}

export function SetUsernameFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetUsername {
    if (json == null) {
        return json;
    }
    return {
        
        'current_password': json['current_password'],
        'new_email': json['new_email'] == null ? undefined : json['new_email'],
    };
}

export function SetUsernameToJSON(json: any): SetUsername {
    return SetUsernameToJSONTyped(json, false);
}

export function SetUsernameToJSONTyped(value?: SetUsername | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'current_password': value['current_password'],
        'new_email': value['new_email'],
    };
}

