/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DiplomaTypeEnum } from './DiplomaTypeEnum';
import {
    DiplomaTypeEnumFromJSON,
    DiplomaTypeEnumFromJSONTyped,
    DiplomaTypeEnumToJSON,
    DiplomaTypeEnumToJSONTyped,
} from './DiplomaTypeEnum';

/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface PatchedNotNestedDiploma
 */
export interface PatchedNotNestedDiploma {
    /**
     * 
     * @type {number}
     * @memberof PatchedNotNestedDiploma
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof PatchedNotNestedDiploma
     */
    readonly diploma_url?: string;
    /**
     * Type de diplôme
     * 
     * * `619` - AFPS
     * * `73` - BAFA
     * * `140` - BAFD
     * * `618` - BP JEPS
     * * `615` - Diplome Marin
     * * `1000` - Équivalence BAFD
     * * `632` - DE Educateur Spécialisé 
     * * `626` - DE JEPS
     * * `603` - PSC1 Prevention et Secours Civiques de niveau 1
     * * `608` - Surveillant de Baignade
     * * `1001` - Autre Diplome ou Brevet
     * @type {DiplomaTypeEnum}
     * @memberof PatchedNotNestedDiploma
     */
    diploma_type?: DiplomaTypeEnum;
    /**
     * Date d'obtention du diplome
     * @type {Date}
     * @memberof PatchedNotNestedDiploma
     */
    issue_date?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedNotNestedDiploma
     */
    readonly diploma_available?: boolean;
}



/**
 * Check if a given object implements the PatchedNotNestedDiploma interface.
 */
export function instanceOfPatchedNotNestedDiploma(value: object): value is PatchedNotNestedDiploma {
    return true;
}

export function PatchedNotNestedDiplomaFromJSON(json: any): PatchedNotNestedDiploma {
    return PatchedNotNestedDiplomaFromJSONTyped(json, false);
}

export function PatchedNotNestedDiplomaFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedNotNestedDiploma {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'diploma_url': json['diploma_url'] == null ? undefined : json['diploma_url'],
        'diploma_type': json['diploma_type'] == null ? undefined : DiplomaTypeEnumFromJSON(json['diploma_type']),
        'issue_date': json['issue_date'] == null ? undefined : (new Date(json['issue_date'])),
        'diploma_available': json['diploma_available'] == null ? undefined : json['diploma_available'],
    };
}

export function PatchedNotNestedDiplomaToJSON(json: any): PatchedNotNestedDiploma {
    return PatchedNotNestedDiplomaToJSONTyped(json, false);
}

export function PatchedNotNestedDiplomaToJSONTyped(value?: Omit<PatchedNotNestedDiploma, 'id'|'diploma_url'|'diploma_available'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'diploma_type': DiplomaTypeEnumToJSON(value['diploma_type']),
        'issue_date': value['issue_date'] == null ? undefined : ((value['issue_date']).toISOString().substring(0,10)),
    };
}

