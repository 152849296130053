import revive_payload_client_WEQvxo6SCF from "/app/common/temp/node_modules/.pnpm/nuxt@3.8.0_sass@1.80.6/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_7lXThNSk9B from "/app/common/temp/node_modules/.pnpm/nuxt@3.8.0_sass@1.80.6/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ZFTyJhy1Cm from "/app/common/temp/node_modules/.pnpm/nuxt@3.8.0_sass@1.80.6/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_uHAtFxjvRb from "/app/common/temp/node_modules/.pnpm/nuxt@3.8.0_sass@1.80.6/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_EjZ5wy3MJU from "/app/common/temp/node_modules/.pnpm/@pinia+nuxt@0.5.1_vue@3.5.12/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/webapp/.nuxt/components.plugin.mjs";
import prefetch_client_j9JyiFsSly from "/app/common/temp/node_modules/.pnpm/nuxt@3.8.0_sass@1.80.6/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_PpCimOcRfB from "/app/common/temp/node_modules/.pnpm/pinia-plugin-persistedstate@4.1.3/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import chunk_reload_client_wdEHdzQOzI from "/app/common/temp/node_modules/.pnpm/nuxt@3.8.0_sass@1.80.6/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_USAH8VR2kh from "/app/common/temp/node_modules/.pnpm/nuxt@3.8.0_sass@1.80.6/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import api_service_XSI3K6oOsN from "/app/webapp/plugins/api.service.ts";
import error_ldt3PQauZ0 from "/app/webapp/plugins/error.ts";
import i18n_VfGcjrvSkj from "/app/webapp/plugins/i18n.ts";
import internet_connection_qmNk5ogxp5 from "/app/webapp/plugins/internet-connection.ts";
import sentry_3AyO8nEfhE from "/app/webapp/plugins/sentry.ts";
import vue_query_wrmMkNpEpe from "/app/webapp/plugins/vue-query.ts";
export default [
  revive_payload_client_WEQvxo6SCF,
  unhead_7lXThNSk9B,
  router_ZFTyJhy1Cm,
  payload_client_uHAtFxjvRb,
  plugin_vue3_EjZ5wy3MJU,
  components_plugin_KR1HBZs4kY,
  prefetch_client_j9JyiFsSly,
  plugin_PpCimOcRfB,
  chunk_reload_client_wdEHdzQOzI,
  check_outdated_build_client_USAH8VR2kh,
  api_service_XSI3K6oOsN,
  error_ldt3PQauZ0,
  i18n_VfGcjrvSkj,
  internet_connection_qmNk5ogxp5,
  sentry_3AyO8nEfhE,
  vue_query_wrmMkNpEpe
]