/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Meeting } from './Meeting';
import {
    MeetingFromJSON,
    MeetingFromJSONTyped,
    MeetingToJSON,
    MeetingToJSONTyped,
} from './Meeting';

/**
 * 
 * @export
 * @interface PaginatedMeetingList
 */
export interface PaginatedMeetingList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedMeetingList
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedMeetingList
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedMeetingList
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<Meeting>}
     * @memberof PaginatedMeetingList
     */
    results: Array<Meeting>;
}

/**
 * Check if a given object implements the PaginatedMeetingList interface.
 */
export function instanceOfPaginatedMeetingList(value: object): value is PaginatedMeetingList {
    if (!('count' in value) || value['count'] === undefined) return false;
    if (!('results' in value) || value['results'] === undefined) return false;
    return true;
}

export function PaginatedMeetingListFromJSON(json: any): PaginatedMeetingList {
    return PaginatedMeetingListFromJSONTyped(json, false);
}

export function PaginatedMeetingListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedMeetingList {
    if (json == null) {
        return json;
    }
    return {
        
        'count': json['count'],
        'next': json['next'] == null ? undefined : json['next'],
        'previous': json['previous'] == null ? undefined : json['previous'],
        'results': ((json['results'] as Array<any>).map(MeetingFromJSON)),
    };
}

export function PaginatedMeetingListToJSON(json: any): PaginatedMeetingList {
    return PaginatedMeetingListToJSONTyped(json, false);
}

export function PaginatedMeetingListToJSONTyped(value?: PaginatedMeetingList | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'count': value['count'],
        'next': value['next'],
        'previous': value['previous'],
        'results': ((value['results'] as Array<any>).map(MeetingToJSON)),
    };
}

