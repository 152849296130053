/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NotNestedPublicActivityCategory } from './NotNestedPublicActivityCategory';
import {
    NotNestedPublicActivityCategoryFromJSON,
    NotNestedPublicActivityCategoryFromJSONTyped,
    NotNestedPublicActivityCategoryToJSON,
    NotNestedPublicActivityCategoryToJSONTyped,
} from './NotNestedPublicActivityCategory';
import type { Track } from './Track';
import {
    TrackFromJSON,
    TrackFromJSONTyped,
    TrackToJSON,
    TrackToJSONTyped,
} from './Track';

/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface PublicActivity
 */
export interface PublicActivity {
    /**
     * 
     * @type {string}
     * @memberof PublicActivity
     */
    readonly id: string;
    /**
     * Must not be null, an Activity must be linked to a meeting
     * @type {string}
     * @memberof PublicActivity
     */
    readonly meeting_id: string;
    /**
     * 
     * @type {NotNestedPublicActivityCategory}
     * @memberof PublicActivity
     */
    readonly category: NotNestedPublicActivityCategory;
    /**
     * 
     * @type {Array<Track>}
     * @memberof PublicActivity
     */
    readonly tracks: Array<Track>;
    /**
     * 
     * @type {string}
     * @memberof PublicActivity
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof PublicActivity
     */
    description?: string | null;
    /**
     * Nombre maximum de participants autorisés lors de cette Activity
     * @type {number}
     * @memberof PublicActivity
     */
    max_participants?: number;
}

/**
 * Check if a given object implements the PublicActivity interface.
 */
export function instanceOfPublicActivity(value: object): value is PublicActivity {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('meeting_id' in value) || value['meeting_id'] === undefined) return false;
    if (!('category' in value) || value['category'] === undefined) return false;
    if (!('tracks' in value) || value['tracks'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    return true;
}

export function PublicActivityFromJSON(json: any): PublicActivity {
    return PublicActivityFromJSONTyped(json, false);
}

export function PublicActivityFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublicActivity {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'meeting_id': json['meeting_id'],
        'category': NotNestedPublicActivityCategoryFromJSON(json['category']),
        'tracks': ((json['tracks'] as Array<any>).map(TrackFromJSON)),
        'label': json['label'],
        'description': json['description'] == null ? undefined : json['description'],
        'max_participants': json['max_participants'] == null ? undefined : json['max_participants'],
    };
}

export function PublicActivityToJSON(json: any): PublicActivity {
    return PublicActivityToJSONTyped(json, false);
}

export function PublicActivityToJSONTyped(value?: Omit<PublicActivity, 'id'|'meeting_id'|'category'|'tracks'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'label': value['label'],
        'description': value['description'],
        'max_participants': value['max_participants'],
    };
}

