/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ResourceTypeEnum } from './ResourceTypeEnum';
import {
    ResourceTypeEnumFromJSON,
    ResourceTypeEnumFromJSONTyped,
    ResourceTypeEnumToJSON,
    ResourceTypeEnumToJSONTyped,
} from './ResourceTypeEnum';

/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface PatchedNotNestedResource
 */
export interface PatchedNotNestedResource {
    /**
     * 
     * @type {string}
     * @memberof PatchedNotNestedResource
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedNotNestedResource
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedNotNestedResource
     */
    description?: string | null;
    /**
     * Type de diplôme
     * 
     * * `content` - Contenu
     * * `link` - Lien
     * * `media` - Média
     * * `category` - Catégorie
     * @type {ResourceTypeEnum}
     * @memberof PatchedNotNestedResource
     */
    resource_type?: ResourceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PatchedNotNestedResource
     */
    url?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedNotNestedResource
     */
    content?: string | null;
    /**
     * La ressource a-t-elle été créée par l'équipe de formation
     * @type {boolean}
     * @memberof PatchedNotNestedResource
     */
    trusted?: boolean;
    /**
     * Date de publication de la resource
     * @type {Date}
     * @memberof PatchedNotNestedResource
     */
    publication_date?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedNotNestedResource
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof PatchedNotNestedResource
     */
    parent?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedNotNestedResource
     */
    unpublish?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatchedNotNestedResource
     */
    readonly media_url?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedNotNestedResource
     */
    readonly media_available?: boolean;
}



/**
 * Check if a given object implements the PatchedNotNestedResource interface.
 */
export function instanceOfPatchedNotNestedResource(value: object): value is PatchedNotNestedResource {
    return true;
}

export function PatchedNotNestedResourceFromJSON(json: any): PatchedNotNestedResource {
    return PatchedNotNestedResourceFromJSONTyped(json, false);
}

export function PatchedNotNestedResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedNotNestedResource {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'label': json['label'] == null ? undefined : json['label'],
        'description': json['description'] == null ? undefined : json['description'],
        'resource_type': json['resource_type'] == null ? undefined : ResourceTypeEnumFromJSON(json['resource_type']),
        'url': json['url'] == null ? undefined : json['url'],
        'content': json['content'] == null ? undefined : json['content'],
        'trusted': json['trusted'] == null ? undefined : json['trusted'],
        'publication_date': json['publication_date'] == null ? undefined : (new Date(json['publication_date'])),
        'order': json['order'] == null ? undefined : json['order'],
        'parent': json['parent'] == null ? undefined : json['parent'],
        'unpublish': json['unpublish'] == null ? undefined : json['unpublish'],
        'media_url': json['media_url'] == null ? undefined : json['media_url'],
        'media_available': json['media_available'] == null ? undefined : json['media_available'],
    };
}

export function PatchedNotNestedResourceToJSON(json: any): PatchedNotNestedResource {
    return PatchedNotNestedResourceToJSONTyped(json, false);
}

export function PatchedNotNestedResourceToJSONTyped(value?: Omit<PatchedNotNestedResource, 'media_url'|'media_available'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'label': value['label'],
        'description': value['description'],
        'resource_type': ResourceTypeEnumToJSON(value['resource_type']),
        'url': value['url'],
        'content': value['content'],
        'trusted': value['trusted'],
        'publication_date': value['publication_date'] == null ? undefined : ((value['publication_date'] as any).toISOString()),
        'order': value['order'],
        'parent': value['parent'],
        'unpublish': value['unpublish'],
    };
}

