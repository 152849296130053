/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `content` - Contenu
 * * `link` - Lien
 * * `media` - Média
 * * `category` - Catégorie
 * @export
 */
export const ResourceTypeEnum = {
    Content: 'content',
    Link: 'link',
    Media: 'media',
    Category: 'category'
} as const;
export type ResourceTypeEnum = typeof ResourceTypeEnum[keyof typeof ResourceTypeEnum];


export function instanceOfResourceTypeEnum(value: any): boolean {
    for (const key in ResourceTypeEnum) {
        if (Object.prototype.hasOwnProperty.call(ResourceTypeEnum, key)) {
            if (ResourceTypeEnum[key as keyof typeof ResourceTypeEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ResourceTypeEnumFromJSON(json: any): ResourceTypeEnum {
    return ResourceTypeEnumFromJSONTyped(json, false);
}

export function ResourceTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResourceTypeEnum {
    return json as ResourceTypeEnum;
}

export function ResourceTypeEnumToJSON(value?: ResourceTypeEnum | null): any {
    return value as any;
}

export function ResourceTypeEnumToJSONTyped(value: any, ignoreDiscriminator: boolean): ResourceTypeEnum {
    return value as ResourceTypeEnum;
}

