/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DocTemplate } from './DocTemplate';
import {
    DocTemplateFromJSON,
    DocTemplateFromJSONTyped,
    DocTemplateToJSON,
    DocTemplateToJSONTyped,
} from './DocTemplate';

/**
 * 
 * @export
 * @interface PaginatedDocTemplateList
 */
export interface PaginatedDocTemplateList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedDocTemplateList
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedDocTemplateList
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedDocTemplateList
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<DocTemplate>}
     * @memberof PaginatedDocTemplateList
     */
    results: Array<DocTemplate>;
}

/**
 * Check if a given object implements the PaginatedDocTemplateList interface.
 */
export function instanceOfPaginatedDocTemplateList(value: object): value is PaginatedDocTemplateList {
    if (!('count' in value) || value['count'] === undefined) return false;
    if (!('results' in value) || value['results'] === undefined) return false;
    return true;
}

export function PaginatedDocTemplateListFromJSON(json: any): PaginatedDocTemplateList {
    return PaginatedDocTemplateListFromJSONTyped(json, false);
}

export function PaginatedDocTemplateListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedDocTemplateList {
    if (json == null) {
        return json;
    }
    return {
        
        'count': json['count'],
        'next': json['next'] == null ? undefined : json['next'],
        'previous': json['previous'] == null ? undefined : json['previous'],
        'results': ((json['results'] as Array<any>).map(DocTemplateFromJSON)),
    };
}

export function PaginatedDocTemplateListToJSON(json: any): PaginatedDocTemplateList {
    return PaginatedDocTemplateListToJSONTyped(json, false);
}

export function PaginatedDocTemplateListToJSONTyped(value?: PaginatedDocTemplateList | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'count': value['count'],
        'next': value['next'],
        'previous': value['previous'],
        'results': ((value['results'] as Array<any>).map(DocTemplateToJSON)),
    };
}

