/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UsernameResetConfirm
 */
export interface UsernameResetConfirm {
    /**
     * 
     * @type {string}
     * @memberof UsernameResetConfirm
     */
    new_email?: string;
}

/**
 * Check if a given object implements the UsernameResetConfirm interface.
 */
export function instanceOfUsernameResetConfirm(value: object): value is UsernameResetConfirm {
    return true;
}

export function UsernameResetConfirmFromJSON(json: any): UsernameResetConfirm {
    return UsernameResetConfirmFromJSONTyped(json, false);
}

export function UsernameResetConfirmFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsernameResetConfirm {
    if (json == null) {
        return json;
    }
    return {
        
        'new_email': json['new_email'] == null ? undefined : json['new_email'],
    };
}

export function UsernameResetConfirmToJSON(json: any): UsernameResetConfirm {
    return UsernameResetConfirmToJSONTyped(json, false);
}

export function UsernameResetConfirmToJSONTyped(value?: UsernameResetConfirm | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'new_email': value['new_email'],
    };
}

