/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  NotNestedPublicTeamCategory,
  NotNestedTeam,
  PaginatedTeamList,
  ParticipantsTeam,
  PatchedNotNestedPublicTeamCategory,
  PatchedTeam,
  Team,
} from '../models/index';
import {
    NotNestedPublicTeamCategoryFromJSON,
    NotNestedPublicTeamCategoryToJSON,
    NotNestedTeamFromJSON,
    NotNestedTeamToJSON,
    PaginatedTeamListFromJSON,
    PaginatedTeamListToJSON,
    ParticipantsTeamFromJSON,
    ParticipantsTeamToJSON,
    PatchedNotNestedPublicTeamCategoryFromJSON,
    PatchedNotNestedPublicTeamCategoryToJSON,
    PatchedTeamFromJSON,
    PatchedTeamToJSON,
    TeamFromJSON,
    TeamToJSON,
} from '../models/index';

export interface MeetingsTeamsCategoriesCreateRequest {
    meetingUuid: string;
    notNestedPublicTeamCategory: Omit<NotNestedPublicTeamCategory, 'id'|'meeting'>;
}

export interface MeetingsTeamsCategoriesDestroyRequest {
    meetingUuid: string;
    uuid: string;
}

export interface MeetingsTeamsCategoriesListRequest {
    meetingUuid: string;
    ordering?: string;
    search?: string;
}

export interface MeetingsTeamsCategoriesPartialUpdateRequest {
    meetingUuid: string;
    uuid: string;
    patchedNotNestedPublicTeamCategory?: Omit<PatchedNotNestedPublicTeamCategory, 'id'|'meeting'>;
}

export interface MeetingsTeamsCategoriesRetrieveRequest {
    meetingUuid: string;
    uuid: string;
}

export interface MeetingsTeamsCategoriesUpdateRequest {
    meetingUuid: string;
    uuid: string;
    notNestedPublicTeamCategory: Omit<NotNestedPublicTeamCategory, 'id'|'meeting'>;
}

export interface MeetingsTeamsCreateRequest {
    meetingUuid: string;
    notNestedTeam: Omit<NotNestedTeam, 'id'|'meeting'>;
}

export interface MeetingsTeamsDestroyRequest {
    meetingUuid: string;
    uuid: string;
}

export interface MeetingsTeamsListRequest {
    meetingUuid: string;
    ordering?: string;
    page?: number;
    pageSize?: number;
    search?: string;
}

export interface MeetingsTeamsPartialUpdateRequest {
    meetingUuid: string;
    uuid: string;
    patchedTeam?: Omit<PatchedTeam, 'id'|'meeting'>;
}

export interface MeetingsTeamsParticipantsRetrieveRequest {
    meetingUuid: string;
    uuid: string;
}

export interface MeetingsTeamsParticipantsUpdateRequest {
    meetingUuid: string;
    uuid: string;
    participantsTeam: ParticipantsTeam;
}

export interface MeetingsTeamsRetrieveRequest {
    meetingUuid: string;
    uuid: string;
}

export interface MeetingsTeamsUpdateRequest {
    meetingUuid: string;
    uuid: string;
    notNestedTeam: Omit<NotNestedTeam, 'id'|'meeting'>;
}

/**
 * 
 */
export class TeamsApi extends runtime.BaseAPI {

    /**
     * TODO
     */
    async meetingsTeamsCategoriesCreateRaw(requestParameters: MeetingsTeamsCategoriesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotNestedPublicTeamCategory>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsTeamsCategoriesCreate().'
            );
        }

        if (requestParameters['notNestedPublicTeamCategory'] == null) {
            throw new runtime.RequiredError(
                'notNestedPublicTeamCategory',
                'Required parameter "notNestedPublicTeamCategory" was null or undefined when calling meetingsTeamsCategoriesCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/teams-categories/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotNestedPublicTeamCategoryToJSON(requestParameters['notNestedPublicTeamCategory']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotNestedPublicTeamCategoryFromJSON(jsonValue));
    }

    /**
     * TODO
     */
    async meetingsTeamsCategoriesCreate(requestParameters: MeetingsTeamsCategoriesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotNestedPublicTeamCategory> {
        const response = await this.meetingsTeamsCategoriesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * TODO
     */
    async meetingsTeamsCategoriesDestroyRaw(requestParameters: MeetingsTeamsCategoriesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsTeamsCategoriesDestroy().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsTeamsCategoriesDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/teams-categories/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * TODO
     */
    async meetingsTeamsCategoriesDestroy(requestParameters: MeetingsTeamsCategoriesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.meetingsTeamsCategoriesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * TODO
     */
    async meetingsTeamsCategoriesListRaw(requestParameters: MeetingsTeamsCategoriesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<NotNestedPublicTeamCategory>>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsTeamsCategoriesList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/teams-categories/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NotNestedPublicTeamCategoryFromJSON));
    }

    /**
     * TODO
     */
    async meetingsTeamsCategoriesList(requestParameters: MeetingsTeamsCategoriesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<NotNestedPublicTeamCategory>> {
        const response = await this.meetingsTeamsCategoriesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * TODO
     */
    async meetingsTeamsCategoriesPartialUpdateRaw(requestParameters: MeetingsTeamsCategoriesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotNestedPublicTeamCategory>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsTeamsCategoriesPartialUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsTeamsCategoriesPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/teams-categories/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedNotNestedPublicTeamCategoryToJSON(requestParameters['patchedNotNestedPublicTeamCategory']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotNestedPublicTeamCategoryFromJSON(jsonValue));
    }

    /**
     * TODO
     */
    async meetingsTeamsCategoriesPartialUpdate(requestParameters: MeetingsTeamsCategoriesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotNestedPublicTeamCategory> {
        const response = await this.meetingsTeamsCategoriesPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * TODO
     */
    async meetingsTeamsCategoriesRetrieveRaw(requestParameters: MeetingsTeamsCategoriesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotNestedPublicTeamCategory>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsTeamsCategoriesRetrieve().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsTeamsCategoriesRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/teams-categories/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotNestedPublicTeamCategoryFromJSON(jsonValue));
    }

    /**
     * TODO
     */
    async meetingsTeamsCategoriesRetrieve(requestParameters: MeetingsTeamsCategoriesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotNestedPublicTeamCategory> {
        const response = await this.meetingsTeamsCategoriesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * TODO
     */
    async meetingsTeamsCategoriesUpdateRaw(requestParameters: MeetingsTeamsCategoriesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotNestedPublicTeamCategory>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsTeamsCategoriesUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsTeamsCategoriesUpdate().'
            );
        }

        if (requestParameters['notNestedPublicTeamCategory'] == null) {
            throw new runtime.RequiredError(
                'notNestedPublicTeamCategory',
                'Required parameter "notNestedPublicTeamCategory" was null or undefined when calling meetingsTeamsCategoriesUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/teams-categories/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NotNestedPublicTeamCategoryToJSON(requestParameters['notNestedPublicTeamCategory']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotNestedPublicTeamCategoryFromJSON(jsonValue));
    }

    /**
     * TODO
     */
    async meetingsTeamsCategoriesUpdate(requestParameters: MeetingsTeamsCategoriesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotNestedPublicTeamCategory> {
        const response = await this.meetingsTeamsCategoriesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new team for the meeting
     */
    async meetingsTeamsCreateRaw(requestParameters: MeetingsTeamsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotNestedTeam>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsTeamsCreate().'
            );
        }

        if (requestParameters['notNestedTeam'] == null) {
            throw new runtime.RequiredError(
                'notNestedTeam',
                'Required parameter "notNestedTeam" was null or undefined when calling meetingsTeamsCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/teams/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotNestedTeamToJSON(requestParameters['notNestedTeam']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotNestedTeamFromJSON(jsonValue));
    }

    /**
     * Create a new team for the meeting
     */
    async meetingsTeamsCreate(requestParameters: MeetingsTeamsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotNestedTeam> {
        const response = await this.meetingsTeamsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a team
     */
    async meetingsTeamsDestroyRaw(requestParameters: MeetingsTeamsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsTeamsDestroy().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsTeamsDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/teams/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a team
     */
    async meetingsTeamsDestroy(requestParameters: MeetingsTeamsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.meetingsTeamsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * List the teams for the meeting
     */
    async meetingsTeamsListRaw(requestParameters: MeetingsTeamsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedTeamList>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsTeamsList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/teams/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedTeamListFromJSON(jsonValue));
    }

    /**
     * List the teams for the meeting
     */
    async meetingsTeamsList(requestParameters: MeetingsTeamsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedTeamList> {
        const response = await this.meetingsTeamsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Partially update a single team
     */
    async meetingsTeamsPartialUpdateRaw(requestParameters: MeetingsTeamsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Team>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsTeamsPartialUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsTeamsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/teams/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedTeamToJSON(requestParameters['patchedTeam']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamFromJSON(jsonValue));
    }

    /**
     * Partially update a single team
     */
    async meetingsTeamsPartialUpdate(requestParameters: MeetingsTeamsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Team> {
        const response = await this.meetingsTeamsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the list of MeetingParticipants associated to a Team
     */
    async meetingsTeamsParticipantsRetrieveRaw(requestParameters: MeetingsTeamsParticipantsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ParticipantsTeam>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsTeamsParticipantsRetrieve().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsTeamsParticipantsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/teams/{uuid}/participants/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ParticipantsTeamFromJSON(jsonValue));
    }

    /**
     * Get the list of MeetingParticipants associated to a Team
     */
    async meetingsTeamsParticipantsRetrieve(requestParameters: MeetingsTeamsParticipantsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ParticipantsTeam> {
        const response = await this.meetingsTeamsParticipantsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the list of MeetingParticipants associated to a Team Only MeetingParticipant of this list will be assigned
     */
    async meetingsTeamsParticipantsUpdateRaw(requestParameters: MeetingsTeamsParticipantsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ParticipantsTeam>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsTeamsParticipantsUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsTeamsParticipantsUpdate().'
            );
        }

        if (requestParameters['participantsTeam'] == null) {
            throw new runtime.RequiredError(
                'participantsTeam',
                'Required parameter "participantsTeam" was null or undefined when calling meetingsTeamsParticipantsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/teams/{uuid}/participants/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ParticipantsTeamToJSON(requestParameters['participantsTeam']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ParticipantsTeamFromJSON(jsonValue));
    }

    /**
     * Update the list of MeetingParticipants associated to a Team Only MeetingParticipant of this list will be assigned
     */
    async meetingsTeamsParticipantsUpdate(requestParameters: MeetingsTeamsParticipantsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ParticipantsTeam> {
        const response = await this.meetingsTeamsParticipantsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve a single team from the meeting
     */
    async meetingsTeamsRetrieveRaw(requestParameters: MeetingsTeamsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Team>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsTeamsRetrieve().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsTeamsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/teams/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamFromJSON(jsonValue));
    }

    /**
     * Retrieve a single team from the meeting
     */
    async meetingsTeamsRetrieve(requestParameters: MeetingsTeamsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Team> {
        const response = await this.meetingsTeamsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a single team
     */
    async meetingsTeamsUpdateRaw(requestParameters: MeetingsTeamsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotNestedTeam>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsTeamsUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsTeamsUpdate().'
            );
        }

        if (requestParameters['notNestedTeam'] == null) {
            throw new runtime.RequiredError(
                'notNestedTeam',
                'Required parameter "notNestedTeam" was null or undefined when calling meetingsTeamsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/teams/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NotNestedTeamToJSON(requestParameters['notNestedTeam']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotNestedTeamFromJSON(jsonValue));
    }

    /**
     * Update a single team
     */
    async meetingsTeamsUpdate(requestParameters: MeetingsTeamsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotNestedTeam> {
        const response = await this.meetingsTeamsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
