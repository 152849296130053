/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NotNestedMeetingRole } from './NotNestedMeetingRole';
import {
    NotNestedMeetingRoleFromJSON,
    NotNestedMeetingRoleFromJSONTyped,
    NotNestedMeetingRoleToJSON,
    NotNestedMeetingRoleToJSONTyped,
} from './NotNestedMeetingRole';

/**
 * 
 * @export
 * @interface PaginatedNotNestedMeetingRoleList
 */
export interface PaginatedNotNestedMeetingRoleList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedNotNestedMeetingRoleList
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedNotNestedMeetingRoleList
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedNotNestedMeetingRoleList
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<NotNestedMeetingRole>}
     * @memberof PaginatedNotNestedMeetingRoleList
     */
    results: Array<NotNestedMeetingRole>;
}

/**
 * Check if a given object implements the PaginatedNotNestedMeetingRoleList interface.
 */
export function instanceOfPaginatedNotNestedMeetingRoleList(value: object): value is PaginatedNotNestedMeetingRoleList {
    if (!('count' in value) || value['count'] === undefined) return false;
    if (!('results' in value) || value['results'] === undefined) return false;
    return true;
}

export function PaginatedNotNestedMeetingRoleListFromJSON(json: any): PaginatedNotNestedMeetingRoleList {
    return PaginatedNotNestedMeetingRoleListFromJSONTyped(json, false);
}

export function PaginatedNotNestedMeetingRoleListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedNotNestedMeetingRoleList {
    if (json == null) {
        return json;
    }
    return {
        
        'count': json['count'],
        'next': json['next'] == null ? undefined : json['next'],
        'previous': json['previous'] == null ? undefined : json['previous'],
        'results': ((json['results'] as Array<any>).map(NotNestedMeetingRoleFromJSON)),
    };
}

export function PaginatedNotNestedMeetingRoleListToJSON(json: any): PaginatedNotNestedMeetingRoleList {
    return PaginatedNotNestedMeetingRoleListToJSONTyped(json, false);
}

export function PaginatedNotNestedMeetingRoleListToJSONTyped(value?: PaginatedNotNestedMeetingRoleList | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'count': value['count'],
        'next': value['next'],
        'previous': value['previous'],
        'results': ((value['results'] as Array<any>).map(NotNestedMeetingRoleToJSON)),
    };
}

