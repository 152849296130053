/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface NotNestedPublicMeetingParticipantWithAvatar
 */
export interface NotNestedPublicMeetingParticipantWithAvatar {
    /**
     * 
     * @type {string}
     * @memberof NotNestedPublicMeetingParticipantWithAvatar
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof NotNestedPublicMeetingParticipantWithAvatar
     */
    readonly user_id: string;
    /**
     * 
     * @type {string}
     * @memberof NotNestedPublicMeetingParticipantWithAvatar
     */
    readonly first_name: string;
    /**
     * 
     * @type {string}
     * @memberof NotNestedPublicMeetingParticipantWithAvatar
     */
    readonly last_name: string;
    /**
     * 
     * @type {string}
     * @memberof NotNestedPublicMeetingParticipantWithAvatar
     */
    readonly usage_first_name: string;
    /**
     * 
     * @type {string}
     * @memberof NotNestedPublicMeetingParticipantWithAvatar
     */
    readonly usage_last_name: string;
    /**
     * 
     * @type {string}
     * @memberof NotNestedPublicMeetingParticipantWithAvatar
     */
    readonly avatar_url: string;
    /**
     * 
     * @type {boolean}
     * @memberof NotNestedPublicMeetingParticipantWithAvatar
     */
    readonly avatar_available: boolean;
    /**
     * 
     * @type {string}
     * @memberof NotNestedPublicMeetingParticipantWithAvatar
     */
    readonly user_avatar_url: string;
    /**
     * 
     * @type {boolean}
     * @memberof NotNestedPublicMeetingParticipantWithAvatar
     */
    readonly user_avatar_available: boolean;
}

/**
 * Check if a given object implements the NotNestedPublicMeetingParticipantWithAvatar interface.
 */
export function instanceOfNotNestedPublicMeetingParticipantWithAvatar(value: object): value is NotNestedPublicMeetingParticipantWithAvatar {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('user_id' in value) || value['user_id'] === undefined) return false;
    if (!('first_name' in value) || value['first_name'] === undefined) return false;
    if (!('last_name' in value) || value['last_name'] === undefined) return false;
    if (!('usage_first_name' in value) || value['usage_first_name'] === undefined) return false;
    if (!('usage_last_name' in value) || value['usage_last_name'] === undefined) return false;
    if (!('avatar_url' in value) || value['avatar_url'] === undefined) return false;
    if (!('avatar_available' in value) || value['avatar_available'] === undefined) return false;
    if (!('user_avatar_url' in value) || value['user_avatar_url'] === undefined) return false;
    if (!('user_avatar_available' in value) || value['user_avatar_available'] === undefined) return false;
    return true;
}

export function NotNestedPublicMeetingParticipantWithAvatarFromJSON(json: any): NotNestedPublicMeetingParticipantWithAvatar {
    return NotNestedPublicMeetingParticipantWithAvatarFromJSONTyped(json, false);
}

export function NotNestedPublicMeetingParticipantWithAvatarFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotNestedPublicMeetingParticipantWithAvatar {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'user_id': json['user_id'],
        'first_name': json['first_name'],
        'last_name': json['last_name'],
        'usage_first_name': json['usage_first_name'],
        'usage_last_name': json['usage_last_name'],
        'avatar_url': json['avatar_url'],
        'avatar_available': json['avatar_available'],
        'user_avatar_url': json['user_avatar_url'],
        'user_avatar_available': json['user_avatar_available'],
    };
}

export function NotNestedPublicMeetingParticipantWithAvatarToJSON(json: any): NotNestedPublicMeetingParticipantWithAvatar {
    return NotNestedPublicMeetingParticipantWithAvatarToJSONTyped(json, false);
}

export function NotNestedPublicMeetingParticipantWithAvatarToJSONTyped(value?: Omit<NotNestedPublicMeetingParticipantWithAvatar, 'id'|'user_id'|'first_name'|'last_name'|'usage_first_name'|'usage_last_name'|'avatar_url'|'avatar_available'|'user_avatar_url'|'user_avatar_available'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
    };
}

