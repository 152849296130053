/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SetPassword
 */
export interface SetPassword {
    /**
     * 
     * @type {string}
     * @memberof SetPassword
     */
    new_password: string;
    /**
     * 
     * @type {string}
     * @memberof SetPassword
     */
    current_password: string;
}

/**
 * Check if a given object implements the SetPassword interface.
 */
export function instanceOfSetPassword(value: object): value is SetPassword {
    if (!('new_password' in value) || value['new_password'] === undefined) return false;
    if (!('current_password' in value) || value['current_password'] === undefined) return false;
    return true;
}

export function SetPasswordFromJSON(json: any): SetPassword {
    return SetPasswordFromJSONTyped(json, false);
}

export function SetPasswordFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetPassword {
    if (json == null) {
        return json;
    }
    return {
        
        'new_password': json['new_password'],
        'current_password': json['current_password'],
    };
}

export function SetPasswordToJSON(json: any): SetPassword {
    return SetPasswordToJSONTyped(json, false);
}

export function SetPasswordToJSONTyped(value?: SetPassword | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'new_password': value['new_password'],
        'current_password': value['current_password'],
    };
}

