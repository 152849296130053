/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NotNestedMeetingRole } from './NotNestedMeetingRole';
import {
    NotNestedMeetingRoleFromJSON,
    NotNestedMeetingRoleFromJSONTyped,
    NotNestedMeetingRoleToJSON,
    NotNestedMeetingRoleToJSONTyped,
} from './NotNestedMeetingRole';

/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface DocTemplate
 */
export interface DocTemplate {
    /**
     * 
     * @type {string}
     * @memberof DocTemplate
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DocTemplate
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof DocTemplate
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocTemplate
     */
    parent_template?: string | null;
    /**
     * 
     * @type {Array<NotNestedMeetingRole>}
     * @memberof DocTemplate
     */
    readonly involved_roles: Array<NotNestedMeetingRole>;
    /**
     * 
     * @type {string}
     * @memberof DocTemplate
     */
    category?: string | null;
}

/**
 * Check if a given object implements the DocTemplate interface.
 */
export function instanceOfDocTemplate(value: object): value is DocTemplate {
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('involved_roles' in value) || value['involved_roles'] === undefined) return false;
    return true;
}

export function DocTemplateFromJSON(json: any): DocTemplate {
    return DocTemplateFromJSONTyped(json, false);
}

export function DocTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocTemplate {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'label': json['label'],
        'description': json['description'] == null ? undefined : json['description'],
        'parent_template': json['parent_template'] == null ? undefined : json['parent_template'],
        'involved_roles': ((json['involved_roles'] as Array<any>).map(NotNestedMeetingRoleFromJSON)),
        'category': json['category'] == null ? undefined : json['category'],
    };
}

export function DocTemplateToJSON(json: any): DocTemplate {
    return DocTemplateToJSONTyped(json, false);
}

export function DocTemplateToJSONTyped(value?: Omit<DocTemplate, 'involved_roles'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'label': value['label'],
        'description': value['description'],
        'parent_template': value['parent_template'],
        'category': value['category'],
    };
}

