/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface StructureType
 */
export interface StructureType {
    /**
     * 
     * @type {string}
     * @memberof StructureType
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof StructureType
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof StructureType
     */
    structure_code: string;
}

/**
 * Check if a given object implements the StructureType interface.
 */
export function instanceOfStructureType(value: object): value is StructureType {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('structure_code' in value) || value['structure_code'] === undefined) return false;
    return true;
}

export function StructureTypeFromJSON(json: any): StructureType {
    return StructureTypeFromJSONTyped(json, false);
}

export function StructureTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): StructureType {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'label': json['label'],
        'structure_code': json['structure_code'],
    };
}

export function StructureTypeToJSON(json: any): StructureType {
    return StructureTypeToJSONTyped(json, false);
}

export function StructureTypeToJSONTyped(value?: Omit<StructureType, 'id'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'label': value['label'],
        'structure_code': value['structure_code'],
    };
}

