/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NotNestedPublicMeetingParticipantWithAvatar } from './NotNestedPublicMeetingParticipantWithAvatar';
import {
    NotNestedPublicMeetingParticipantWithAvatarFromJSON,
    NotNestedPublicMeetingParticipantWithAvatarFromJSONTyped,
    NotNestedPublicMeetingParticipantWithAvatarToJSON,
    NotNestedPublicMeetingParticipantWithAvatarToJSONTyped,
} from './NotNestedPublicMeetingParticipantWithAvatar';
import type { NotNestedPublicTeamCategory } from './NotNestedPublicTeamCategory';
import {
    NotNestedPublicTeamCategoryFromJSON,
    NotNestedPublicTeamCategoryFromJSONTyped,
    NotNestedPublicTeamCategoryToJSON,
    NotNestedPublicTeamCategoryToJSONTyped,
} from './NotNestedPublicTeamCategory';

/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface Team
 */
export interface Team {
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    color?: string;
    /**
     * The meeting associated to the team
     * @type {string}
     * @memberof Team
     */
    readonly meeting: string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    category_id: string | null;
    /**
     * 
     * @type {Array<NotNestedPublicMeetingParticipantWithAvatar>}
     * @memberof Team
     */
    participants: Array<NotNestedPublicMeetingParticipantWithAvatar>;
    /**
     * 
     * @type {NotNestedPublicTeamCategory}
     * @memberof Team
     */
    category: NotNestedPublicTeamCategory;
}

/**
 * Check if a given object implements the Team interface.
 */
export function instanceOfTeam(value: object): value is Team {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('meeting' in value) || value['meeting'] === undefined) return false;
    if (!('category_id' in value) || value['category_id'] === undefined) return false;
    if (!('participants' in value) || value['participants'] === undefined) return false;
    if (!('category' in value) || value['category'] === undefined) return false;
    return true;
}

export function TeamFromJSON(json: any): Team {
    return TeamFromJSONTyped(json, false);
}

export function TeamFromJSONTyped(json: any, ignoreDiscriminator: boolean): Team {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'label': json['label'],
        'color': json['color'] == null ? undefined : json['color'],
        'meeting': json['meeting'],
        'category_id': json['category_id'],
        'participants': ((json['participants'] as Array<any>).map(NotNestedPublicMeetingParticipantWithAvatarFromJSON)),
        'category': NotNestedPublicTeamCategoryFromJSON(json['category']),
    };
}

export function TeamToJSON(json: any): Team {
    return TeamToJSONTyped(json, false);
}

export function TeamToJSONTyped(value?: Omit<Team, 'id'|'meeting'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'label': value['label'],
        'color': value['color'],
        'category_id': value['category_id'],
        'participants': ((value['participants'] as Array<any>).map(NotNestedPublicMeetingParticipantWithAvatarToJSON)),
        'category': NotNestedPublicTeamCategoryToJSON(value['category']),
    };
}

