/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MeetingFormField } from './MeetingFormField';
import {
    MeetingFormFieldFromJSON,
    MeetingFormFieldFromJSONTyped,
    MeetingFormFieldToJSON,
    MeetingFormFieldToJSONTyped,
} from './MeetingFormField';
import type { ResponseTypeEnum } from './ResponseTypeEnum';
import {
    ResponseTypeEnumFromJSON,
    ResponseTypeEnumFromJSONTyped,
    ResponseTypeEnumToJSON,
    ResponseTypeEnumToJSONTyped,
} from './ResponseTypeEnum';

/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface PatchedMeetingForm
 */
export interface PatchedMeetingForm {
    /**
     * 
     * @type {string}
     * @memberof PatchedMeetingForm
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeetingForm
     */
    title?: string;
    /**
     * 
     * @type {Array<MeetingFormField>}
     * @memberof PatchedMeetingForm
     */
    readonly form_fields?: Array<MeetingFormField>;
    /**
     * 
     * @type {ResponseTypeEnum}
     * @memberof PatchedMeetingForm
     */
    response_type?: ResponseTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof PatchedMeetingForm
     */
    readonly created_at?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PatchedMeetingForm
     */
    readonly updated_at?: Date;
    /**
     * Date de publication du sondage
     * @type {Date}
     * @memberof PatchedMeetingForm
     */
    publication_date?: Date | null;
    /**
     * Date d'ouverture du sondage
     * @type {Date}
     * @memberof PatchedMeetingForm
     */
    opening_date?: Date | null;
    /**
     * Date de cloture du sondage
     * @type {Date}
     * @memberof PatchedMeetingForm
     */
    closing_date?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeetingForm
     */
    author?: string | null;
}



/**
 * Check if a given object implements the PatchedMeetingForm interface.
 */
export function instanceOfPatchedMeetingForm(value: object): value is PatchedMeetingForm {
    return true;
}

export function PatchedMeetingFormFromJSON(json: any): PatchedMeetingForm {
    return PatchedMeetingFormFromJSONTyped(json, false);
}

export function PatchedMeetingFormFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedMeetingForm {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'title': json['title'] == null ? undefined : json['title'],
        'form_fields': json['form_fields'] == null ? undefined : ((json['form_fields'] as Array<any>).map(MeetingFormFieldFromJSON)),
        'response_type': json['response_type'] == null ? undefined : ResponseTypeEnumFromJSON(json['response_type']),
        'created_at': json['created_at'] == null ? undefined : (new Date(json['created_at'])),
        'updated_at': json['updated_at'] == null ? undefined : (new Date(json['updated_at'])),
        'publication_date': json['publication_date'] == null ? undefined : (new Date(json['publication_date'])),
        'opening_date': json['opening_date'] == null ? undefined : (new Date(json['opening_date'])),
        'closing_date': json['closing_date'] == null ? undefined : (new Date(json['closing_date'])),
        'author': json['author'] == null ? undefined : json['author'],
    };
}

export function PatchedMeetingFormToJSON(json: any): PatchedMeetingForm {
    return PatchedMeetingFormToJSONTyped(json, false);
}

export function PatchedMeetingFormToJSONTyped(value?: Omit<PatchedMeetingForm, 'id'|'form_fields'|'created_at'|'updated_at'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'title': value['title'],
        'response_type': ResponseTypeEnumToJSON(value['response_type']),
        'publication_date': value['publication_date'] == null ? undefined : ((value['publication_date'] as any).toISOString()),
        'opening_date': value['opening_date'] == null ? undefined : ((value['opening_date'] as any).toISOString()),
        'closing_date': value['closing_date'] == null ? undefined : ((value['closing_date'] as any).toISOString()),
        'author': value['author'],
    };
}

