/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface PatchedMeetingFormField
 */
export interface PatchedMeetingFormField {
    /**
     * 
     * @type {string}
     * @memberof PatchedMeetingFormField
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeetingFormField
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeetingFormField
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeetingFormField
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeetingFormField
     */
    choices?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeetingFormField
     */
    help_info?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeetingFormField
     */
    default_value?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedMeetingFormField
     */
    is_required?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PatchedMeetingFormField
     */
    order?: number;
}

/**
 * Check if a given object implements the PatchedMeetingFormField interface.
 */
export function instanceOfPatchedMeetingFormField(value: object): value is PatchedMeetingFormField {
    return true;
}

export function PatchedMeetingFormFieldFromJSON(json: any): PatchedMeetingFormField {
    return PatchedMeetingFormFieldFromJSONTyped(json, false);
}

export function PatchedMeetingFormFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedMeetingFormField {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'title': json['title'] == null ? undefined : json['title'],
        'name': json['name'] == null ? undefined : json['name'],
        'type': json['type'] == null ? undefined : json['type'],
        'choices': json['choices'] == null ? undefined : json['choices'],
        'help_info': json['help_info'] == null ? undefined : json['help_info'],
        'default_value': json['default_value'] == null ? undefined : json['default_value'],
        'is_required': json['is_required'] == null ? undefined : json['is_required'],
        'order': json['order'] == null ? undefined : json['order'],
    };
}

export function PatchedMeetingFormFieldToJSON(json: any): PatchedMeetingFormField {
    return PatchedMeetingFormFieldToJSONTyped(json, false);
}

export function PatchedMeetingFormFieldToJSONTyped(value?: Omit<PatchedMeetingFormField, 'id'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'title': value['title'],
        'name': value['name'],
        'type': value['type'],
        'choices': value['choices'],
        'help_info': value['help_info'],
        'default_value': value['default_value'],
        'is_required': value['is_required'],
        'order': value['order'],
    };
}

