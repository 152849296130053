/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `1` - Non défini
 * * `2` - Favorable
 * * `3` - Défavorable
 * @export
 */
export const SessionStatusEnum = {
    _1: '1',
    _2: '2',
    _3: '3'
} as const;
export type SessionStatusEnum = typeof SessionStatusEnum[keyof typeof SessionStatusEnum];


export function instanceOfSessionStatusEnum(value: any): boolean {
    for (const key in SessionStatusEnum) {
        if (Object.prototype.hasOwnProperty.call(SessionStatusEnum, key)) {
            if (SessionStatusEnum[key as keyof typeof SessionStatusEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function SessionStatusEnumFromJSON(json: any): SessionStatusEnum {
    return SessionStatusEnumFromJSONTyped(json, false);
}

export function SessionStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): SessionStatusEnum {
    return json as SessionStatusEnum;
}

export function SessionStatusEnumToJSON(value?: SessionStatusEnum | null): any {
    return value as any;
}

export function SessionStatusEnumToJSONTyped(value: any, ignoreDiscriminator: boolean): SessionStatusEnum {
    return value as SessionStatusEnum;
}

