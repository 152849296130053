/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Activation
 */
export interface Activation {
    /**
     * 
     * @type {string}
     * @memberof Activation
     */
    uid: string;
    /**
     * 
     * @type {string}
     * @memberof Activation
     */
    token: string;
}

/**
 * Check if a given object implements the Activation interface.
 */
export function instanceOfActivation(value: object): value is Activation {
    if (!('uid' in value) || value['uid'] === undefined) return false;
    if (!('token' in value) || value['token'] === undefined) return false;
    return true;
}

export function ActivationFromJSON(json: any): Activation {
    return ActivationFromJSONTyped(json, false);
}

export function ActivationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Activation {
    if (json == null) {
        return json;
    }
    return {
        
        'uid': json['uid'],
        'token': json['token'],
    };
}

export function ActivationToJSON(json: any): Activation {
    return ActivationToJSONTyped(json, false);
}

export function ActivationToJSONTyped(value?: Activation | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'uid': value['uid'],
        'token': value['token'],
    };
}

