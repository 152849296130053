/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Activation,
  Djoser,
  PaginatedUserList,
  PasswordResetConfirmRetype,
  PatchedUser,
  ProviderAuth,
  SendEmailReset,
  SetPassword,
  SetUsername,
  Token,
  TokenObtainPairEmail,
  TokenRefresh,
  TokenVerify,
  User,
  UserCreate,
  UsernameResetConfirm,
} from '../models/index';
import {
    ActivationFromJSON,
    ActivationToJSON,
    DjoserFromJSON,
    DjoserToJSON,
    PaginatedUserListFromJSON,
    PaginatedUserListToJSON,
    PasswordResetConfirmRetypeFromJSON,
    PasswordResetConfirmRetypeToJSON,
    PatchedUserFromJSON,
    PatchedUserToJSON,
    ProviderAuthFromJSON,
    ProviderAuthToJSON,
    SendEmailResetFromJSON,
    SendEmailResetToJSON,
    SetPasswordFromJSON,
    SetPasswordToJSON,
    SetUsernameFromJSON,
    SetUsernameToJSON,
    TokenFromJSON,
    TokenToJSON,
    TokenObtainPairEmailFromJSON,
    TokenObtainPairEmailToJSON,
    TokenRefreshFromJSON,
    TokenRefreshToJSON,
    TokenVerifyFromJSON,
    TokenVerifyToJSON,
    UserFromJSON,
    UserToJSON,
    UserCreateFromJSON,
    UserCreateToJSON,
    UsernameResetConfirmFromJSON,
    UsernameResetConfirmToJSON,
} from '../models/index';

export interface AuthJwtCreateCreateRequest {
    tokenObtainPairEmail: TokenObtainPairEmail;
}

export interface AuthJwtRefreshCreateRequest {
    tokenRefresh: Omit<TokenRefresh, 'access'>;
}

export interface AuthJwtVerifyCreateRequest {
    tokenVerify: TokenVerify;
}

export interface AuthOCreateRequest {
    code: string;
    provider: string;
    sessionState: string;
    state: string;
    providerAuth?: Omit<ProviderAuth, 'access'|'refresh'|'user'>;
}

export interface AuthORetrieveRequest {
    provider: string;
}

export interface AuthUsersActivationCreateRequest {
    activation: Activation;
}

export interface AuthUsersCreateRequest {
    userCreate: Omit<UserCreate, 'id'>;
}

export interface AuthUsersDestroyRequest {
    id: string;
}

export interface AuthUsersListRequest {
    limit?: number;
    offset?: number;
}

export interface AuthUsersMePartialUpdateRequest {
    patchedUser?: Omit<PatchedUser, 'email'|'id'>;
}

export interface AuthUsersMeUpdateRequest {
    user?: Omit<User, 'email'|'id'>;
}

export interface AuthUsersPartialUpdateRequest {
    id: string;
    patchedUser?: Omit<PatchedUser, 'email'|'id'>;
}

export interface AuthUsersResendActivationCreateRequest {
    sendEmailReset: SendEmailReset;
}

export interface AuthUsersResetPasswordConfirmCreateRequest {
    passwordResetConfirmRetype: PasswordResetConfirmRetype;
}

export interface AuthUsersResetPasswordCreateRequest {
    sendEmailReset: SendEmailReset;
}

export interface AuthUsersResetUsernameConfirmCreateRequest {
    usernameResetConfirm?: UsernameResetConfirm;
}

export interface AuthUsersResetUsernameCreateRequest {
    sendEmailReset: SendEmailReset;
}

export interface AuthUsersRetrieveRequest {
    id: string;
}

export interface AuthUsersSetPasswordCreateRequest {
    setPassword: SetPassword;
}

export interface AuthUsersSetUsernameCreateRequest {
    setUsername: SetUsername;
}

export interface AuthUsersUpdateRequest {
    id: string;
    user?: Omit<User, 'email'|'id'>;
}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI {

    /**
     * Takes a set of user credentials and returns an access and refresh JSON web token pair to prove the authentication of those credentials.
     */
    async authJwtCreateCreateRaw(requestParameters: AuthJwtCreateCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Token>> {
        if (requestParameters['tokenObtainPairEmail'] == null) {
            throw new runtime.RequiredError(
                'tokenObtainPairEmail',
                'Required parameter "tokenObtainPairEmail" was null or undefined when calling authJwtCreateCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/auth/jwt/create/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TokenObtainPairEmailToJSON(requestParameters['tokenObtainPairEmail']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenFromJSON(jsonValue));
    }

    /**
     * Takes a set of user credentials and returns an access and refresh JSON web token pair to prove the authentication of those credentials.
     */
    async authJwtCreateCreate(requestParameters: AuthJwtCreateCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Token> {
        const response = await this.authJwtCreateCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
     */
    async authJwtRefreshCreateRaw(requestParameters: AuthJwtRefreshCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenRefresh>> {
        if (requestParameters['tokenRefresh'] == null) {
            throw new runtime.RequiredError(
                'tokenRefresh',
                'Required parameter "tokenRefresh" was null or undefined when calling authJwtRefreshCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/auth/jwt/refresh/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TokenRefreshToJSON(requestParameters['tokenRefresh']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenRefreshFromJSON(jsonValue));
    }

    /**
     * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
     */
    async authJwtRefreshCreate(requestParameters: AuthJwtRefreshCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenRefresh> {
        const response = await this.authJwtRefreshCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Takes a token and indicates if it is valid.  This view provides no information about a token\'s fitness for a particular use.
     */
    async authJwtVerifyCreateRaw(requestParameters: AuthJwtVerifyCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenVerify>> {
        if (requestParameters['tokenVerify'] == null) {
            throw new runtime.RequiredError(
                'tokenVerify',
                'Required parameter "tokenVerify" was null or undefined when calling authJwtVerifyCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/auth/jwt/verify/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TokenVerifyToJSON(requestParameters['tokenVerify']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenVerifyFromJSON(jsonValue));
    }

    /**
     * Takes a token and indicates if it is valid.  This view provides no information about a token\'s fitness for a particular use.
     */
    async authJwtVerifyCreate(requestParameters: AuthJwtVerifyCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenVerify> {
        const response = await this.authJwtVerifyCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Confirmer l\'authentification par keycloak
     */
    async authOCreateRaw(requestParameters: AuthOCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProviderAuth>> {
        if (requestParameters['code'] == null) {
            throw new runtime.RequiredError(
                'code',
                'Required parameter "code" was null or undefined when calling authOCreate().'
            );
        }

        if (requestParameters['provider'] == null) {
            throw new runtime.RequiredError(
                'provider',
                'Required parameter "provider" was null or undefined when calling authOCreate().'
            );
        }

        if (requestParameters['sessionState'] == null) {
            throw new runtime.RequiredError(
                'sessionState',
                'Required parameter "sessionState" was null or undefined when calling authOCreate().'
            );
        }

        if (requestParameters['state'] == null) {
            throw new runtime.RequiredError(
                'state',
                'Required parameter "state" was null or undefined when calling authOCreate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['code'] != null) {
            queryParameters['code'] = requestParameters['code'];
        }

        if (requestParameters['sessionState'] != null) {
            queryParameters['session_state'] = requestParameters['sessionState'];
        }

        if (requestParameters['state'] != null) {
            queryParameters['state'] = requestParameters['state'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/auth/o/{provider}/`.replace(`{${"provider"}}`, encodeURIComponent(String(requestParameters['provider']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProviderAuthToJSON(requestParameters['providerAuth']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProviderAuthFromJSON(jsonValue));
    }

    /**
     * Confirmer l\'authentification par keycloak
     */
    async authOCreate(requestParameters: AuthOCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProviderAuth> {
        const response = await this.authOCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Demande une url d\'authentication pour keycloak
     */
    async authORetrieveRaw(requestParameters: AuthORetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Djoser>> {
        if (requestParameters['provider'] == null) {
            throw new runtime.RequiredError(
                'provider',
                'Required parameter "provider" was null or undefined when calling authORetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/auth/o/{provider}/`.replace(`{${"provider"}}`, encodeURIComponent(String(requestParameters['provider']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DjoserFromJSON(jsonValue));
    }

    /**
     * Demande une url d\'authentication pour keycloak
     */
    async authORetrieve(requestParameters: AuthORetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Djoser> {
        const response = await this.authORetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async authUsersActivationCreateRaw(requestParameters: AuthUsersActivationCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Activation>> {
        if (requestParameters['activation'] == null) {
            throw new runtime.RequiredError(
                'activation',
                'Required parameter "activation" was null or undefined when calling authUsersActivationCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/auth/users/activation/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ActivationToJSON(requestParameters['activation']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ActivationFromJSON(jsonValue));
    }

    /**
     */
    async authUsersActivationCreate(requestParameters: AuthUsersActivationCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Activation> {
        const response = await this.authUsersActivationCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async authUsersCreateRaw(requestParameters: AuthUsersCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserCreate>> {
        if (requestParameters['userCreate'] == null) {
            throw new runtime.RequiredError(
                'userCreate',
                'Required parameter "userCreate" was null or undefined when calling authUsersCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/auth/users/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserCreateToJSON(requestParameters['userCreate']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserCreateFromJSON(jsonValue));
    }

    /**
     */
    async authUsersCreate(requestParameters: AuthUsersCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserCreate> {
        const response = await this.authUsersCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async authUsersDestroyRaw(requestParameters: AuthUsersDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling authUsersDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/auth/users/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async authUsersDestroy(requestParameters: AuthUsersDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.authUsersDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async authUsersListRaw(requestParameters: AuthUsersListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedUserList>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/auth/users/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedUserListFromJSON(jsonValue));
    }

    /**
     */
    async authUsersList(requestParameters: AuthUsersListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedUserList> {
        const response = await this.authUsersListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async authUsersMeDestroyRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/auth/users/me/`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async authUsersMeDestroy(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.authUsersMeDestroyRaw(initOverrides);
    }

    /**
     */
    async authUsersMePartialUpdateRaw(requestParameters: AuthUsersMePartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/auth/users/me/`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedUserToJSON(requestParameters['patchedUser']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     */
    async authUsersMePartialUpdate(requestParameters: AuthUsersMePartialUpdateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.authUsersMePartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async authUsersMeRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/auth/users/me/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     */
    async authUsersMeRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.authUsersMeRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async authUsersMeUpdateRaw(requestParameters: AuthUsersMeUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/auth/users/me/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserToJSON(requestParameters['user']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     */
    async authUsersMeUpdate(requestParameters: AuthUsersMeUpdateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.authUsersMeUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async authUsersPartialUpdateRaw(requestParameters: AuthUsersPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling authUsersPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/auth/users/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedUserToJSON(requestParameters['patchedUser']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     */
    async authUsersPartialUpdate(requestParameters: AuthUsersPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.authUsersPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async authUsersResendActivationCreateRaw(requestParameters: AuthUsersResendActivationCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SendEmailReset>> {
        if (requestParameters['sendEmailReset'] == null) {
            throw new runtime.RequiredError(
                'sendEmailReset',
                'Required parameter "sendEmailReset" was null or undefined when calling authUsersResendActivationCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/auth/users/resend_activation/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendEmailResetToJSON(requestParameters['sendEmailReset']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SendEmailResetFromJSON(jsonValue));
    }

    /**
     */
    async authUsersResendActivationCreate(requestParameters: AuthUsersResendActivationCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SendEmailReset> {
        const response = await this.authUsersResendActivationCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reset password confirm
     */
    async authUsersResetPasswordConfirmCreateRaw(requestParameters: AuthUsersResetPasswordConfirmCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['passwordResetConfirmRetype'] == null) {
            throw new runtime.RequiredError(
                'passwordResetConfirmRetype',
                'Required parameter "passwordResetConfirmRetype" was null or undefined when calling authUsersResetPasswordConfirmCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/auth/users/reset_password_confirm/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PasswordResetConfirmRetypeToJSON(requestParameters['passwordResetConfirmRetype']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Reset password confirm
     */
    async authUsersResetPasswordConfirmCreate(requestParameters: AuthUsersResetPasswordConfirmCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.authUsersResetPasswordConfirmCreateRaw(requestParameters, initOverrides);
    }

    /**
     * Reset password
     */
    async authUsersResetPasswordCreateRaw(requestParameters: AuthUsersResetPasswordCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['sendEmailReset'] == null) {
            throw new runtime.RequiredError(
                'sendEmailReset',
                'Required parameter "sendEmailReset" was null or undefined when calling authUsersResetPasswordCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/auth/users/reset_password/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendEmailResetToJSON(requestParameters['sendEmailReset']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Reset password
     */
    async authUsersResetPasswordCreate(requestParameters: AuthUsersResetPasswordCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.authUsersResetPasswordCreateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async authUsersResetUsernameConfirmCreateRaw(requestParameters: AuthUsersResetUsernameConfirmCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UsernameResetConfirm>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/auth/users/reset_username_confirm/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UsernameResetConfirmToJSON(requestParameters['usernameResetConfirm']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UsernameResetConfirmFromJSON(jsonValue));
    }

    /**
     */
    async authUsersResetUsernameConfirmCreate(requestParameters: AuthUsersResetUsernameConfirmCreateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UsernameResetConfirm> {
        const response = await this.authUsersResetUsernameConfirmCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async authUsersResetUsernameCreateRaw(requestParameters: AuthUsersResetUsernameCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SendEmailReset>> {
        if (requestParameters['sendEmailReset'] == null) {
            throw new runtime.RequiredError(
                'sendEmailReset',
                'Required parameter "sendEmailReset" was null or undefined when calling authUsersResetUsernameCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/auth/users/reset_username/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendEmailResetToJSON(requestParameters['sendEmailReset']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SendEmailResetFromJSON(jsonValue));
    }

    /**
     */
    async authUsersResetUsernameCreate(requestParameters: AuthUsersResetUsernameCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SendEmailReset> {
        const response = await this.authUsersResetUsernameCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async authUsersRetrieveRaw(requestParameters: AuthUsersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling authUsersRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/auth/users/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     */
    async authUsersRetrieve(requestParameters: AuthUsersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.authUsersRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async authUsersSetPasswordCreateRaw(requestParameters: AuthUsersSetPasswordCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SetPassword>> {
        if (requestParameters['setPassword'] == null) {
            throw new runtime.RequiredError(
                'setPassword',
                'Required parameter "setPassword" was null or undefined when calling authUsersSetPasswordCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/auth/users/set_password/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetPasswordToJSON(requestParameters['setPassword']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SetPasswordFromJSON(jsonValue));
    }

    /**
     */
    async authUsersSetPasswordCreate(requestParameters: AuthUsersSetPasswordCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SetPassword> {
        const response = await this.authUsersSetPasswordCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async authUsersSetUsernameCreateRaw(requestParameters: AuthUsersSetUsernameCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SetUsername>> {
        if (requestParameters['setUsername'] == null) {
            throw new runtime.RequiredError(
                'setUsername',
                'Required parameter "setUsername" was null or undefined when calling authUsersSetUsernameCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/auth/users/set_username/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetUsernameToJSON(requestParameters['setUsername']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SetUsernameFromJSON(jsonValue));
    }

    /**
     */
    async authUsersSetUsernameCreate(requestParameters: AuthUsersSetUsernameCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SetUsername> {
        const response = await this.authUsersSetUsernameCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async authUsersUpdateRaw(requestParameters: AuthUsersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling authUsersUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/auth/users/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserToJSON(requestParameters['user']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     */
    async authUsersUpdate(requestParameters: AuthUsersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.authUsersUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
