/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PaginatedReadonlyStructureList,
} from '../models/index';
import {
    PaginatedReadonlyStructureListFromJSON,
    PaginatedReadonlyStructureListToJSON,
} from '../models/index';

export interface StructuresCentresRessourceListRequest {
    ordering?: string;
    page?: number;
    pageSize?: number;
    search?: string;
}

export interface StructuresOrganisatriceListRequest {
    onlyMine?: boolean;
    onlyNational?: boolean;
    ordering?: string;
    page?: number;
    pageSize?: number;
    search?: string;
}

/**
 * 
 */
export class StructuresApi extends runtime.BaseAPI {

    /**
     * Liste les centres de ressources, pour deslistes déroulantes par exemple
     */
    async structuresCentresRessourceListRaw(requestParameters: StructuresCentresRessourceListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedReadonlyStructureList>> {
        const queryParameters: any = {};

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/structures/centres-ressource/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedReadonlyStructureListFromJSON(jsonValue));
    }

    /**
     * Liste les centres de ressources, pour deslistes déroulantes par exemple
     */
    async structuresCentresRessourceList(requestParameters: StructuresCentresRessourceListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedReadonlyStructureList> {
        const response = await this.structuresCentresRessourceListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Liste les structures organisatrices de stage, pour deslistes déroulantes par exemple
     */
    async structuresOrganisatriceListRaw(requestParameters: StructuresOrganisatriceListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedReadonlyStructureList>> {
        const queryParameters: any = {};

        if (requestParameters['onlyMine'] != null) {
            queryParameters['only_mine'] = requestParameters['onlyMine'];
        }

        if (requestParameters['onlyNational'] != null) {
            queryParameters['only_national'] = requestParameters['onlyNational'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/structures/organisatrice/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedReadonlyStructureListFromJSON(jsonValue));
    }

    /**
     * Liste les structures organisatrices de stage, pour deslistes déroulantes par exemple
     */
    async structuresOrganisatriceList(requestParameters: StructuresOrganisatriceListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedReadonlyStructureList> {
        const response = await this.structuresOrganisatriceListRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
