/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface MeetingFormSubmission
 */
export interface MeetingFormSubmission {
    /**
     * 
     * @type {string}
     * @memberof MeetingFormSubmission
     */
    readonly id: string;
    /**
     * 
     * @type {Date}
     * @memberof MeetingFormSubmission
     */
    readonly submitted_at: Date;
    /**
     * 
     * @type {any}
     * @memberof MeetingFormSubmission
     */
    submission_data: any | null;
    /**
     * 
     * @type {string}
     * @memberof MeetingFormSubmission
     */
    readonly url: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingFormSubmission
     */
    participant?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MeetingFormSubmission
     */
    team?: string | null;
}

/**
 * Check if a given object implements the MeetingFormSubmission interface.
 */
export function instanceOfMeetingFormSubmission(value: object): value is MeetingFormSubmission {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('submitted_at' in value) || value['submitted_at'] === undefined) return false;
    if (!('submission_data' in value) || value['submission_data'] === undefined) return false;
    if (!('url' in value) || value['url'] === undefined) return false;
    return true;
}

export function MeetingFormSubmissionFromJSON(json: any): MeetingFormSubmission {
    return MeetingFormSubmissionFromJSONTyped(json, false);
}

export function MeetingFormSubmissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeetingFormSubmission {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'submitted_at': (new Date(json['submitted_at'])),
        'submission_data': json['submission_data'],
        'url': json['url'],
        'participant': json['participant'] == null ? undefined : json['participant'],
        'team': json['team'] == null ? undefined : json['team'],
    };
}

export function MeetingFormSubmissionToJSON(json: any): MeetingFormSubmission {
    return MeetingFormSubmissionToJSONTyped(json, false);
}

export function MeetingFormSubmissionToJSONTyped(value?: Omit<MeetingFormSubmission, 'id'|'submitted_at'|'url'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'submission_data': value['submission_data'],
        'participant': value['participant'],
        'team': value['team'],
    };
}

