/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DailyTaskTeam,
  NotNestedDailyTaskTeam,
  PaginatedDailyTaskTeamList,
  PaginatedTeamDailyTaskList,
  ParticipantsDailyTaskTeam,
  PatchedDailyTaskTeam,
  TeamDailyTask,
} from '../models/index';
import {
    DailyTaskTeamFromJSON,
    DailyTaskTeamToJSON,
    NotNestedDailyTaskTeamFromJSON,
    NotNestedDailyTaskTeamToJSON,
    PaginatedDailyTaskTeamListFromJSON,
    PaginatedDailyTaskTeamListToJSON,
    PaginatedTeamDailyTaskListFromJSON,
    PaginatedTeamDailyTaskListToJSON,
    ParticipantsDailyTaskTeamFromJSON,
    ParticipantsDailyTaskTeamToJSON,
    PatchedDailyTaskTeamFromJSON,
    PatchedDailyTaskTeamToJSON,
    TeamDailyTaskFromJSON,
    TeamDailyTaskToJSON,
} from '../models/index';

export interface MeetingsDailyTaskTeamsCreateRequest {
    meetingUuid: string;
    notNestedDailyTaskTeam: Omit<NotNestedDailyTaskTeam, 'id'|'meeting'>;
}

export interface MeetingsDailyTaskTeamsDestroyRequest {
    meetingUuid: string;
    uuid: string;
}

export interface MeetingsDailyTaskTeamsListRequest {
    meetingUuid: string;
    ordering?: string;
    page?: number;
    pageSize?: number;
    search?: string;
}

export interface MeetingsDailyTaskTeamsPartialUpdateRequest {
    meetingUuid: string;
    uuid: string;
    patchedDailyTaskTeam?: Omit<PatchedDailyTaskTeam, 'id'|'meeting'>;
}

export interface MeetingsDailyTaskTeamsParticipantsRetrieveRequest {
    meetingUuid: string;
    uuid: string;
}

export interface MeetingsDailyTaskTeamsParticipantsUpdateRequest {
    meetingUuid: string;
    uuid: string;
    participantsDailyTaskTeam: ParticipantsDailyTaskTeam;
}

export interface MeetingsDailyTaskTeamsRetrieveRequest {
    meetingUuid: string;
    uuid: string;
}

export interface MeetingsDailyTaskTeamsTeamDailyTasksListRequest {
    meetingUuid: string;
    teamUuid: string;
    limit?: number;
    offset?: number;
    ordering?: string;
    search?: string;
}

export interface MeetingsDailyTaskTeamsTeamDailyTasksRetrieveRequest {
    id: string;
    meetingUuid: string;
    teamUuid: string;
}

export interface MeetingsDailyTaskTeamsUpdateRequest {
    meetingUuid: string;
    uuid: string;
    notNestedDailyTaskTeam: Omit<NotNestedDailyTaskTeam, 'id'|'meeting'>;
}

/**
 * 
 */
export class DailyTaskTeamsApi extends runtime.BaseAPI {

    /**
     * Create a new DailyTaskTeams for the meeting
     */
    async meetingsDailyTaskTeamsCreateRaw(requestParameters: MeetingsDailyTaskTeamsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotNestedDailyTaskTeam>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsDailyTaskTeamsCreate().'
            );
        }

        if (requestParameters['notNestedDailyTaskTeam'] == null) {
            throw new runtime.RequiredError(
                'notNestedDailyTaskTeam',
                'Required parameter "notNestedDailyTaskTeam" was null or undefined when calling meetingsDailyTaskTeamsCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/daily-task-teams/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotNestedDailyTaskTeamToJSON(requestParameters['notNestedDailyTaskTeam']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotNestedDailyTaskTeamFromJSON(jsonValue));
    }

    /**
     * Create a new DailyTaskTeams for the meeting
     */
    async meetingsDailyTaskTeamsCreate(requestParameters: MeetingsDailyTaskTeamsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotNestedDailyTaskTeam> {
        const response = await this.meetingsDailyTaskTeamsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a DailyTaskTeams
     */
    async meetingsDailyTaskTeamsDestroyRaw(requestParameters: MeetingsDailyTaskTeamsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsDailyTaskTeamsDestroy().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsDailyTaskTeamsDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/daily-task-teams/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a DailyTaskTeams
     */
    async meetingsDailyTaskTeamsDestroy(requestParameters: MeetingsDailyTaskTeamsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.meetingsDailyTaskTeamsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * List the DailyTaskTeams for the meeting
     */
    async meetingsDailyTaskTeamsListRaw(requestParameters: MeetingsDailyTaskTeamsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedDailyTaskTeamList>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsDailyTaskTeamsList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/daily-task-teams/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDailyTaskTeamListFromJSON(jsonValue));
    }

    /**
     * List the DailyTaskTeams for the meeting
     */
    async meetingsDailyTaskTeamsList(requestParameters: MeetingsDailyTaskTeamsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedDailyTaskTeamList> {
        const response = await this.meetingsDailyTaskTeamsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Partially update a single DailyTaskTeams
     */
    async meetingsDailyTaskTeamsPartialUpdateRaw(requestParameters: MeetingsDailyTaskTeamsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DailyTaskTeam>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsDailyTaskTeamsPartialUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsDailyTaskTeamsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/daily-task-teams/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedDailyTaskTeamToJSON(requestParameters['patchedDailyTaskTeam']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DailyTaskTeamFromJSON(jsonValue));
    }

    /**
     * Partially update a single DailyTaskTeams
     */
    async meetingsDailyTaskTeamsPartialUpdate(requestParameters: MeetingsDailyTaskTeamsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DailyTaskTeam> {
        const response = await this.meetingsDailyTaskTeamsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the list of MeetingParticipants associated to a DailyTaskTeam
     */
    async meetingsDailyTaskTeamsParticipantsRetrieveRaw(requestParameters: MeetingsDailyTaskTeamsParticipantsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ParticipantsDailyTaskTeam>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsDailyTaskTeamsParticipantsRetrieve().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsDailyTaskTeamsParticipantsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/daily-task-teams/{uuid}/participants/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ParticipantsDailyTaskTeamFromJSON(jsonValue));
    }

    /**
     * Get the list of MeetingParticipants associated to a DailyTaskTeam
     */
    async meetingsDailyTaskTeamsParticipantsRetrieve(requestParameters: MeetingsDailyTaskTeamsParticipantsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ParticipantsDailyTaskTeam> {
        const response = await this.meetingsDailyTaskTeamsParticipantsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the list of MeetingParticipants associated to a DailyTaskTeam Only MeetingParticipant of this list will be assigned
     */
    async meetingsDailyTaskTeamsParticipantsUpdateRaw(requestParameters: MeetingsDailyTaskTeamsParticipantsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ParticipantsDailyTaskTeam>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsDailyTaskTeamsParticipantsUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsDailyTaskTeamsParticipantsUpdate().'
            );
        }

        if (requestParameters['participantsDailyTaskTeam'] == null) {
            throw new runtime.RequiredError(
                'participantsDailyTaskTeam',
                'Required parameter "participantsDailyTaskTeam" was null or undefined when calling meetingsDailyTaskTeamsParticipantsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/daily-task-teams/{uuid}/participants/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ParticipantsDailyTaskTeamToJSON(requestParameters['participantsDailyTaskTeam']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ParticipantsDailyTaskTeamFromJSON(jsonValue));
    }

    /**
     * Update the list of MeetingParticipants associated to a DailyTaskTeam Only MeetingParticipant of this list will be assigned
     */
    async meetingsDailyTaskTeamsParticipantsUpdate(requestParameters: MeetingsDailyTaskTeamsParticipantsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ParticipantsDailyTaskTeam> {
        const response = await this.meetingsDailyTaskTeamsParticipantsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve a single DailyTaskTeams from the meeting
     */
    async meetingsDailyTaskTeamsRetrieveRaw(requestParameters: MeetingsDailyTaskTeamsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DailyTaskTeam>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsDailyTaskTeamsRetrieve().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsDailyTaskTeamsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/daily-task-teams/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DailyTaskTeamFromJSON(jsonValue));
    }

    /**
     * Retrieve a single DailyTaskTeams from the meeting
     */
    async meetingsDailyTaskTeamsRetrieve(requestParameters: MeetingsDailyTaskTeamsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DailyTaskTeam> {
        const response = await this.meetingsDailyTaskTeamsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retourne la liste des Services assignes à une Equipe pour une Formation donnée.
     */
    async meetingsDailyTaskTeamsTeamDailyTasksListRaw(requestParameters: MeetingsDailyTaskTeamsTeamDailyTasksListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedTeamDailyTaskList>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsDailyTaskTeamsTeamDailyTasksList().'
            );
        }

        if (requestParameters['teamUuid'] == null) {
            throw new runtime.RequiredError(
                'teamUuid',
                'Required parameter "teamUuid" was null or undefined when calling meetingsDailyTaskTeamsTeamDailyTasksList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/daily-task-teams/{team_uuid}/team-daily-tasks/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"team_uuid"}}`, encodeURIComponent(String(requestParameters['teamUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedTeamDailyTaskListFromJSON(jsonValue));
    }

    /**
     * Retourne la liste des Services assignes à une Equipe pour une Formation donnée.
     */
    async meetingsDailyTaskTeamsTeamDailyTasksList(requestParameters: MeetingsDailyTaskTeamsTeamDailyTasksListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedTeamDailyTaskList> {
        const response = await this.meetingsDailyTaskTeamsTeamDailyTasksListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Récupère les informations d\'une instance de Service assigné à une Equipe via son ID pour une Formation donnée.
     */
    async meetingsDailyTaskTeamsTeamDailyTasksRetrieveRaw(requestParameters: MeetingsDailyTaskTeamsTeamDailyTasksRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TeamDailyTask>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling meetingsDailyTaskTeamsTeamDailyTasksRetrieve().'
            );
        }

        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsDailyTaskTeamsTeamDailyTasksRetrieve().'
            );
        }

        if (requestParameters['teamUuid'] == null) {
            throw new runtime.RequiredError(
                'teamUuid',
                'Required parameter "teamUuid" was null or undefined when calling meetingsDailyTaskTeamsTeamDailyTasksRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/daily-task-teams/{team_uuid}/team-daily-tasks/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"team_uuid"}}`, encodeURIComponent(String(requestParameters['teamUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamDailyTaskFromJSON(jsonValue));
    }

    /**
     * Récupère les informations d\'une instance de Service assigné à une Equipe via son ID pour une Formation donnée.
     */
    async meetingsDailyTaskTeamsTeamDailyTasksRetrieve(requestParameters: MeetingsDailyTaskTeamsTeamDailyTasksRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TeamDailyTask> {
        const response = await this.meetingsDailyTaskTeamsTeamDailyTasksRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a single DailyTaskTeams
     */
    async meetingsDailyTaskTeamsUpdateRaw(requestParameters: MeetingsDailyTaskTeamsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotNestedDailyTaskTeam>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsDailyTaskTeamsUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsDailyTaskTeamsUpdate().'
            );
        }

        if (requestParameters['notNestedDailyTaskTeam'] == null) {
            throw new runtime.RequiredError(
                'notNestedDailyTaskTeam',
                'Required parameter "notNestedDailyTaskTeam" was null or undefined when calling meetingsDailyTaskTeamsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/daily-task-teams/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NotNestedDailyTaskTeamToJSON(requestParameters['notNestedDailyTaskTeam']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotNestedDailyTaskTeamFromJSON(jsonValue));
    }

    /**
     * Update a single DailyTaskTeams
     */
    async meetingsDailyTaskTeamsUpdate(requestParameters: MeetingsDailyTaskTeamsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotNestedDailyTaskTeam> {
        const response = await this.meetingsDailyTaskTeamsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
