/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NestedMeetingComment } from './NestedMeetingComment';
import {
    NestedMeetingCommentFromJSON,
    NestedMeetingCommentFromJSONTyped,
    NestedMeetingCommentToJSON,
    NestedMeetingCommentToJSONTyped,
} from './NestedMeetingComment';
import type { SessionStatusEnum } from './SessionStatusEnum';
import {
    SessionStatusEnumFromJSON,
    SessionStatusEnumFromJSONTyped,
    SessionStatusEnumToJSON,
    SessionStatusEnumToJSONTyped,
} from './SessionStatusEnum';
import type { NotNestedMeetingRole } from './NotNestedMeetingRole';
import {
    NotNestedMeetingRoleFromJSON,
    NotNestedMeetingRoleFromJSONTyped,
    NotNestedMeetingRoleToJSON,
    NotNestedMeetingRoleToJSONTyped,
} from './NotNestedMeetingRole';

/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface OpinionParticipant
 */
export interface OpinionParticipant {
    /**
     * 
     * @type {string}
     * @memberof OpinionParticipant
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof OpinionParticipant
     */
    readonly meeting_id: string;
    /**
     * 
     * @type {NotNestedMeetingRole}
     * @memberof OpinionParticipant
     */
    readonly role: NotNestedMeetingRole;
    /**
     * 
     * @type {string}
     * @memberof OpinionParticipant
     */
    readonly admin_first_name: string;
    /**
     * 
     * @type {string}
     * @memberof OpinionParticipant
     */
    readonly admin_last_name: string;
    /**
     * 
     * @type {NestedMeetingComment}
     * @memberof OpinionParticipant
     */
    js_opinion?: NestedMeetingComment | null;
    /**
     * 
     * @type {NestedMeetingComment}
     * @memberof OpinionParticipant
     */
    sgdf_opinion?: NestedMeetingComment | null;
    /**
     * 
     * @type {SessionStatusEnum}
     * @memberof OpinionParticipant
     */
    session_status?: SessionStatusEnum;
}



/**
 * Check if a given object implements the OpinionParticipant interface.
 */
export function instanceOfOpinionParticipant(value: object): value is OpinionParticipant {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('meeting_id' in value) || value['meeting_id'] === undefined) return false;
    if (!('role' in value) || value['role'] === undefined) return false;
    if (!('admin_first_name' in value) || value['admin_first_name'] === undefined) return false;
    if (!('admin_last_name' in value) || value['admin_last_name'] === undefined) return false;
    return true;
}

export function OpinionParticipantFromJSON(json: any): OpinionParticipant {
    return OpinionParticipantFromJSONTyped(json, false);
}

export function OpinionParticipantFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpinionParticipant {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'meeting_id': json['meeting_id'],
        'role': NotNestedMeetingRoleFromJSON(json['role']),
        'admin_first_name': json['admin_first_name'],
        'admin_last_name': json['admin_last_name'],
        'js_opinion': json['js_opinion'] == null ? undefined : NestedMeetingCommentFromJSON(json['js_opinion']),
        'sgdf_opinion': json['sgdf_opinion'] == null ? undefined : NestedMeetingCommentFromJSON(json['sgdf_opinion']),
        'session_status': json['session_status'] == null ? undefined : SessionStatusEnumFromJSON(json['session_status']),
    };
}

export function OpinionParticipantToJSON(json: any): OpinionParticipant {
    return OpinionParticipantToJSONTyped(json, false);
}

export function OpinionParticipantToJSONTyped(value?: Omit<OpinionParticipant, 'id'|'meeting_id'|'role'|'admin_first_name'|'admin_last_name'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'js_opinion': NestedMeetingCommentToJSON(value['js_opinion']),
        'sgdf_opinion': NestedMeetingCommentToJSON(value['sgdf_opinion']),
        'session_status': SessionStatusEnumToJSON(value['session_status']),
    };
}

