/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface PatchedMeetingCommentTag
 */
export interface PatchedMeetingCommentTag {
    /**
     * 
     * @type {string}
     * @memberof PatchedMeetingCommentTag
     */
    readonly id?: string;
    /**
     * a CommentTag is custom to a particular meeting
     * @type {string}
     * @memberof PatchedMeetingCommentTag
     */
    readonly meeting_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeetingCommentTag
     */
    color?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeetingCommentTag
     */
    label?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedMeetingCommentTag
     */
    favorite?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeetingCommentTag
     */
    icon?: string | null;
}

/**
 * Check if a given object implements the PatchedMeetingCommentTag interface.
 */
export function instanceOfPatchedMeetingCommentTag(value: object): value is PatchedMeetingCommentTag {
    return true;
}

export function PatchedMeetingCommentTagFromJSON(json: any): PatchedMeetingCommentTag {
    return PatchedMeetingCommentTagFromJSONTyped(json, false);
}

export function PatchedMeetingCommentTagFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedMeetingCommentTag {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'meeting_id': json['meeting_id'] == null ? undefined : json['meeting_id'],
        'color': json['color'] == null ? undefined : json['color'],
        'label': json['label'] == null ? undefined : json['label'],
        'favorite': json['favorite'] == null ? undefined : json['favorite'],
        'icon': json['icon'] == null ? undefined : json['icon'],
    };
}

export function PatchedMeetingCommentTagToJSON(json: any): PatchedMeetingCommentTag {
    return PatchedMeetingCommentTagToJSONTyped(json, false);
}

export function PatchedMeetingCommentTagToJSONTyped(value?: Omit<PatchedMeetingCommentTag, 'id'|'meeting_id'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'color': value['color'],
        'label': value['label'],
        'favorite': value['favorite'],
        'icon': value['icon'],
    };
}

