/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
    UserToJSONTyped,
} from './User';

/**
 * 
 * @export
 * @interface PaginatedUserList
 */
export interface PaginatedUserList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedUserList
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedUserList
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedUserList
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<User>}
     * @memberof PaginatedUserList
     */
    results: Array<User>;
}

/**
 * Check if a given object implements the PaginatedUserList interface.
 */
export function instanceOfPaginatedUserList(value: object): value is PaginatedUserList {
    if (!('count' in value) || value['count'] === undefined) return false;
    if (!('results' in value) || value['results'] === undefined) return false;
    return true;
}

export function PaginatedUserListFromJSON(json: any): PaginatedUserList {
    return PaginatedUserListFromJSONTyped(json, false);
}

export function PaginatedUserListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedUserList {
    if (json == null) {
        return json;
    }
    return {
        
        'count': json['count'],
        'next': json['next'] == null ? undefined : json['next'],
        'previous': json['previous'] == null ? undefined : json['previous'],
        'results': ((json['results'] as Array<any>).map(UserFromJSON)),
    };
}

export function PaginatedUserListToJSON(json: any): PaginatedUserList {
    return PaginatedUserListToJSONTyped(json, false);
}

export function PaginatedUserListToJSONTyped(value?: PaginatedUserList | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'count': value['count'],
        'next': value['next'],
        'previous': value['previous'],
        'results': ((value['results'] as Array<any>).map(UserToJSON)),
    };
}

