/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MeetingFormField } from './MeetingFormField';
import {
    MeetingFormFieldFromJSON,
    MeetingFormFieldFromJSONTyped,
    MeetingFormFieldToJSON,
    MeetingFormFieldToJSONTyped,
} from './MeetingFormField';
import type { ResponseTypeEnum } from './ResponseTypeEnum';
import {
    ResponseTypeEnumFromJSON,
    ResponseTypeEnumFromJSONTyped,
    ResponseTypeEnumToJSON,
    ResponseTypeEnumToJSONTyped,
} from './ResponseTypeEnum';

/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface MeetingForm
 */
export interface MeetingForm {
    /**
     * 
     * @type {string}
     * @memberof MeetingForm
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingForm
     */
    title: string;
    /**
     * 
     * @type {Array<MeetingFormField>}
     * @memberof MeetingForm
     */
    readonly form_fields: Array<MeetingFormField>;
    /**
     * 
     * @type {ResponseTypeEnum}
     * @memberof MeetingForm
     */
    response_type: ResponseTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof MeetingForm
     */
    readonly created_at: Date;
    /**
     * 
     * @type {Date}
     * @memberof MeetingForm
     */
    readonly updated_at: Date;
    /**
     * Date de publication du sondage
     * @type {Date}
     * @memberof MeetingForm
     */
    publication_date?: Date | null;
    /**
     * Date d'ouverture du sondage
     * @type {Date}
     * @memberof MeetingForm
     */
    opening_date?: Date | null;
    /**
     * Date de cloture du sondage
     * @type {Date}
     * @memberof MeetingForm
     */
    closing_date?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MeetingForm
     */
    author?: string | null;
}



/**
 * Check if a given object implements the MeetingForm interface.
 */
export function instanceOfMeetingForm(value: object): value is MeetingForm {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('form_fields' in value) || value['form_fields'] === undefined) return false;
    if (!('response_type' in value) || value['response_type'] === undefined) return false;
    if (!('created_at' in value) || value['created_at'] === undefined) return false;
    if (!('updated_at' in value) || value['updated_at'] === undefined) return false;
    return true;
}

export function MeetingFormFromJSON(json: any): MeetingForm {
    return MeetingFormFromJSONTyped(json, false);
}

export function MeetingFormFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeetingForm {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'form_fields': ((json['form_fields'] as Array<any>).map(MeetingFormFieldFromJSON)),
        'response_type': ResponseTypeEnumFromJSON(json['response_type']),
        'created_at': (new Date(json['created_at'])),
        'updated_at': (new Date(json['updated_at'])),
        'publication_date': json['publication_date'] == null ? undefined : (new Date(json['publication_date'])),
        'opening_date': json['opening_date'] == null ? undefined : (new Date(json['opening_date'])),
        'closing_date': json['closing_date'] == null ? undefined : (new Date(json['closing_date'])),
        'author': json['author'] == null ? undefined : json['author'],
    };
}

export function MeetingFormToJSON(json: any): MeetingForm {
    return MeetingFormToJSONTyped(json, false);
}

export function MeetingFormToJSONTyped(value?: Omit<MeetingForm, 'id'|'form_fields'|'created_at'|'updated_at'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'title': value['title'],
        'response_type': ResponseTypeEnumToJSON(value['response_type']),
        'publication_date': value['publication_date'] == null ? undefined : ((value['publication_date'] as any).toISOString()),
        'opening_date': value['opening_date'] == null ? undefined : ((value['opening_date'] as any).toISOString()),
        'closing_date': value['closing_date'] == null ? undefined : ((value['closing_date'] as any).toISOString()),
        'author': value['author'],
    };
}

