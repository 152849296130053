/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface PandasTeamDailyTaskByTask
 */
export interface PandasTeamDailyTaskByTask {
    /**
     * 
     * @type {string}
     * @memberof PandasTeamDailyTaskByTask
     */
    daily_task: string;
    /**
     * 
     * @type {Date}
     * @memberof PandasTeamDailyTaskByTask
     */
    date: Date;
    /**
     * 
     * @type {string}
     * @memberof PandasTeamDailyTaskByTask
     */
    team_in_charge: string;
}

/**
 * Check if a given object implements the PandasTeamDailyTaskByTask interface.
 */
export function instanceOfPandasTeamDailyTaskByTask(value: object): value is PandasTeamDailyTaskByTask {
    if (!('daily_task' in value) || value['daily_task'] === undefined) return false;
    if (!('date' in value) || value['date'] === undefined) return false;
    if (!('team_in_charge' in value) || value['team_in_charge'] === undefined) return false;
    return true;
}

export function PandasTeamDailyTaskByTaskFromJSON(json: any): PandasTeamDailyTaskByTask {
    return PandasTeamDailyTaskByTaskFromJSONTyped(json, false);
}

export function PandasTeamDailyTaskByTaskFromJSONTyped(json: any, ignoreDiscriminator: boolean): PandasTeamDailyTaskByTask {
    if (json == null) {
        return json;
    }
    return {
        
        'daily_task': json['daily_task'],
        'date': (new Date(json['date'])),
        'team_in_charge': json['team_in_charge'],
    };
}

export function PandasTeamDailyTaskByTaskToJSON(json: any): PandasTeamDailyTaskByTask {
    return PandasTeamDailyTaskByTaskToJSONTyped(json, false);
}

export function PandasTeamDailyTaskByTaskToJSONTyped(value?: PandasTeamDailyTaskByTask | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'daily_task': value['daily_task'],
        'date': ((value['date']).toISOString().substring(0,10)),
        'team_in_charge': value['team_in_charge'],
    };
}

