/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { IntranetIdEnum } from './IntranetIdEnum';
import {
    IntranetIdEnumFromJSON,
    IntranetIdEnumFromJSONTyped,
    IntranetIdEnumToJSON,
    IntranetIdEnumToJSONTyped,
} from './IntranetIdEnum';

/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface NotNestedMeetingRole
 */
export interface NotNestedMeetingRole {
    /**
     * 
     * @type {string}
     * @memberof NotNestedMeetingRole
     */
    readonly id: string;
    /**
     * 
     * @type {IntranetIdEnum}
     * @memberof NotNestedMeetingRole
     */
    readonly intranet_id: IntranetIdEnum;
    /**
     * 
     * @type {string}
     * @memberof NotNestedMeetingRole
     */
    readonly role: string;
}



/**
 * Check if a given object implements the NotNestedMeetingRole interface.
 */
export function instanceOfNotNestedMeetingRole(value: object): value is NotNestedMeetingRole {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('intranet_id' in value) || value['intranet_id'] === undefined) return false;
    if (!('role' in value) || value['role'] === undefined) return false;
    return true;
}

export function NotNestedMeetingRoleFromJSON(json: any): NotNestedMeetingRole {
    return NotNestedMeetingRoleFromJSONTyped(json, false);
}

export function NotNestedMeetingRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotNestedMeetingRole {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'intranet_id': IntranetIdEnumFromJSON(json['intranet_id']),
        'role': json['role'],
    };
}

export function NotNestedMeetingRoleToJSON(json: any): NotNestedMeetingRole {
    return NotNestedMeetingRoleToJSONTyped(json, false);
}

export function NotNestedMeetingRoleToJSONTyped(value?: Omit<NotNestedMeetingRole, 'id'|'intranet_id'|'role'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
    };
}

