/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface NotNestedPublicRoom
 */
export interface NotNestedPublicRoom {
    /**
     * 
     * @type {string}
     * @memberof NotNestedPublicRoom
     */
    readonly id: string;
    /**
     * Nombre de personnes autorisées dans la salle
     * @type {number}
     * @memberof NotNestedPublicRoom
     */
    capacity: number;
    /**
     * 
     * @type {string}
     * @memberof NotNestedPublicRoom
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof NotNestedPublicRoom
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof NotNestedPublicRoom
     */
    readonly meeting: string;
}

/**
 * Check if a given object implements the NotNestedPublicRoom interface.
 */
export function instanceOfNotNestedPublicRoom(value: object): value is NotNestedPublicRoom {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('capacity' in value) || value['capacity'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('meeting' in value) || value['meeting'] === undefined) return false;
    return true;
}

export function NotNestedPublicRoomFromJSON(json: any): NotNestedPublicRoom {
    return NotNestedPublicRoomFromJSONTyped(json, false);
}

export function NotNestedPublicRoomFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotNestedPublicRoom {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'capacity': json['capacity'],
        'description': json['description'] == null ? undefined : json['description'],
        'label': json['label'],
        'meeting': json['meeting'],
    };
}

export function NotNestedPublicRoomToJSON(json: any): NotNestedPublicRoom {
    return NotNestedPublicRoomToJSONTyped(json, false);
}

export function NotNestedPublicRoomToJSONTyped(value?: Omit<NotNestedPublicRoom, 'id'|'meeting'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'capacity': value['capacity'],
        'description': value['description'],
        'label': value['label'],
    };
}

