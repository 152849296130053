/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NotNestedPublicActivityCategory } from './NotNestedPublicActivityCategory';
import {
    NotNestedPublicActivityCategoryFromJSON,
    NotNestedPublicActivityCategoryFromJSONTyped,
    NotNestedPublicActivityCategoryToJSON,
    NotNestedPublicActivityCategoryToJSONTyped,
} from './NotNestedPublicActivityCategory';
import type { Track } from './Track';
import {
    TrackFromJSON,
    TrackFromJSONTyped,
    TrackToJSON,
    TrackToJSONTyped,
} from './Track';

/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface PatchedPublicActivity
 */
export interface PatchedPublicActivity {
    /**
     * 
     * @type {string}
     * @memberof PatchedPublicActivity
     */
    readonly id?: string;
    /**
     * Must not be null, an Activity must be linked to a meeting
     * @type {string}
     * @memberof PatchedPublicActivity
     */
    readonly meeting_id?: string;
    /**
     * 
     * @type {NotNestedPublicActivityCategory}
     * @memberof PatchedPublicActivity
     */
    readonly category?: NotNestedPublicActivityCategory;
    /**
     * 
     * @type {Array<Track>}
     * @memberof PatchedPublicActivity
     */
    readonly tracks?: Array<Track>;
    /**
     * 
     * @type {string}
     * @memberof PatchedPublicActivity
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedPublicActivity
     */
    description?: string | null;
    /**
     * Nombre maximum de participants autorisés lors de cette Activity
     * @type {number}
     * @memberof PatchedPublicActivity
     */
    max_participants?: number;
}

/**
 * Check if a given object implements the PatchedPublicActivity interface.
 */
export function instanceOfPatchedPublicActivity(value: object): value is PatchedPublicActivity {
    return true;
}

export function PatchedPublicActivityFromJSON(json: any): PatchedPublicActivity {
    return PatchedPublicActivityFromJSONTyped(json, false);
}

export function PatchedPublicActivityFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedPublicActivity {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'meeting_id': json['meeting_id'] == null ? undefined : json['meeting_id'],
        'category': json['category'] == null ? undefined : NotNestedPublicActivityCategoryFromJSON(json['category']),
        'tracks': json['tracks'] == null ? undefined : ((json['tracks'] as Array<any>).map(TrackFromJSON)),
        'label': json['label'] == null ? undefined : json['label'],
        'description': json['description'] == null ? undefined : json['description'],
        'max_participants': json['max_participants'] == null ? undefined : json['max_participants'],
    };
}

export function PatchedPublicActivityToJSON(json: any): PatchedPublicActivity {
    return PatchedPublicActivityToJSONTyped(json, false);
}

export function PatchedPublicActivityToJSONTyped(value?: Omit<PatchedPublicActivity, 'id'|'meeting_id'|'category'|'tracks'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'label': value['label'],
        'description': value['description'],
        'max_participants': value['max_participants'],
    };
}

