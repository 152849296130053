/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ParticipantStructure } from './ParticipantStructure';
import {
    ParticipantStructureFromJSON,
    ParticipantStructureFromJSONTyped,
    ParticipantStructureToJSON,
    ParticipantStructureToJSONTyped,
} from './ParticipantStructure';

/**
 * 
 * @export
 * @interface PaginatedParticipantStructureList
 */
export interface PaginatedParticipantStructureList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedParticipantStructureList
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedParticipantStructureList
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedParticipantStructureList
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<ParticipantStructure>}
     * @memberof PaginatedParticipantStructureList
     */
    results: Array<ParticipantStructure>;
}

/**
 * Check if a given object implements the PaginatedParticipantStructureList interface.
 */
export function instanceOfPaginatedParticipantStructureList(value: object): value is PaginatedParticipantStructureList {
    if (!('count' in value) || value['count'] === undefined) return false;
    if (!('results' in value) || value['results'] === undefined) return false;
    return true;
}

export function PaginatedParticipantStructureListFromJSON(json: any): PaginatedParticipantStructureList {
    return PaginatedParticipantStructureListFromJSONTyped(json, false);
}

export function PaginatedParticipantStructureListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedParticipantStructureList {
    if (json == null) {
        return json;
    }
    return {
        
        'count': json['count'],
        'next': json['next'] == null ? undefined : json['next'],
        'previous': json['previous'] == null ? undefined : json['previous'],
        'results': ((json['results'] as Array<any>).map(ParticipantStructureFromJSON)),
    };
}

export function PaginatedParticipantStructureListToJSON(json: any): PaginatedParticipantStructureList {
    return PaginatedParticipantStructureListToJSONTyped(json, false);
}

export function PaginatedParticipantStructureListToJSONTyped(value?: PaginatedParticipantStructureList | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'count': value['count'],
        'next': value['next'],
        'previous': value['previous'],
        'results': ((value['results'] as Array<any>).map(ParticipantStructureToJSON)),
    };
}

