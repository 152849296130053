/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface PatchedNotNestedPublicActivityCategory
 */
export interface PatchedNotNestedPublicActivityCategory {
    /**
     * 
     * @type {string}
     * @memberof PatchedNotNestedPublicActivityCategory
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedNotNestedPublicActivityCategory
     */
    color?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedNotNestedPublicActivityCategory
     */
    text_color?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedNotNestedPublicActivityCategory
     */
    label?: string;
    /**
     * When a meeting is set, the category is custom to a particular meeting
     * @type {string}
     * @memberof PatchedNotNestedPublicActivityCategory
     */
    readonly meeting?: string | null;
}

/**
 * Check if a given object implements the PatchedNotNestedPublicActivityCategory interface.
 */
export function instanceOfPatchedNotNestedPublicActivityCategory(value: object): value is PatchedNotNestedPublicActivityCategory {
    return true;
}

export function PatchedNotNestedPublicActivityCategoryFromJSON(json: any): PatchedNotNestedPublicActivityCategory {
    return PatchedNotNestedPublicActivityCategoryFromJSONTyped(json, false);
}

export function PatchedNotNestedPublicActivityCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedNotNestedPublicActivityCategory {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'color': json['color'] == null ? undefined : json['color'],
        'text_color': json['text_color'] == null ? undefined : json['text_color'],
        'label': json['label'] == null ? undefined : json['label'],
        'meeting': json['meeting'] == null ? undefined : json['meeting'],
    };
}

export function PatchedNotNestedPublicActivityCategoryToJSON(json: any): PatchedNotNestedPublicActivityCategory {
    return PatchedNotNestedPublicActivityCategoryToJSONTyped(json, false);
}

export function PatchedNotNestedPublicActivityCategoryToJSONTyped(value?: Omit<PatchedNotNestedPublicActivityCategory, 'id'|'meeting'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'color': value['color'],
        'text_color': value['text_color'],
        'label': value['label'],
    };
}

