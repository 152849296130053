/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ReadonlyStructure } from './ReadonlyStructure';
import {
    ReadonlyStructureFromJSON,
    ReadonlyStructureFromJSONTyped,
    ReadonlyStructureToJSON,
    ReadonlyStructureToJSONTyped,
} from './ReadonlyStructure';
import type { NotNestedDailyTaskTeam } from './NotNestedDailyTaskTeam';
import {
    NotNestedDailyTaskTeamFromJSON,
    NotNestedDailyTaskTeamFromJSONTyped,
    NotNestedDailyTaskTeamToJSON,
    NotNestedDailyTaskTeamToJSONTyped,
} from './NotNestedDailyTaskTeam';
import type { NotNestedUser } from './NotNestedUser';
import {
    NotNestedUserFromJSON,
    NotNestedUserFromJSONTyped,
    NotNestedUserToJSON,
    NotNestedUserToJSONTyped,
} from './NotNestedUser';
import type { NotNestedTeam } from './NotNestedTeam';
import {
    NotNestedTeamFromJSON,
    NotNestedTeamFromJSONTyped,
    NotNestedTeamToJSON,
    NotNestedTeamToJSONTyped,
} from './NotNestedTeam';
import type { SessionStatusEnum } from './SessionStatusEnum';
import {
    SessionStatusEnumFromJSON,
    SessionStatusEnumFromJSONTyped,
    SessionStatusEnumToJSON,
    SessionStatusEnumToJSONTyped,
} from './SessionStatusEnum';
import type { NotNestedMeetingRole } from './NotNestedMeetingRole';
import {
    NotNestedMeetingRoleFromJSON,
    NotNestedMeetingRoleFromJSONTyped,
    NotNestedMeetingRoleToJSON,
    NotNestedMeetingRoleToJSONTyped,
} from './NotNestedMeetingRole';
import type { TagadaAvisStatusEnum } from './TagadaAvisStatusEnum';
import {
    TagadaAvisStatusEnumFromJSON,
    TagadaAvisStatusEnumFromJSONTyped,
    TagadaAvisStatusEnumToJSON,
    TagadaAvisStatusEnumToJSONTyped,
} from './TagadaAvisStatusEnum';
import type { TagadaCandidateStatusEnum } from './TagadaCandidateStatusEnum';
import {
    TagadaCandidateStatusEnumFromJSON,
    TagadaCandidateStatusEnumFromJSONTyped,
    TagadaCandidateStatusEnumToJSON,
    TagadaCandidateStatusEnumToJSONTyped,
} from './TagadaCandidateStatusEnum';
import type { Track } from './Track';
import {
    TrackFromJSON,
    TrackFromJSONTyped,
    TrackToJSON,
    TrackToJSONTyped,
} from './Track';
import type { BrancheEnum } from './BrancheEnum';
import {
    BrancheEnumFromJSON,
    BrancheEnumFromJSONTyped,
    BrancheEnumToJSON,
    BrancheEnumToJSONTyped,
} from './BrancheEnum';

/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface PatchedMeetingParticipant
 */
export interface PatchedMeetingParticipant {
    /**
     * 
     * @type {string}
     * @memberof PatchedMeetingParticipant
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeetingParticipant
     */
    readonly user_id?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeetingParticipant
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeetingParticipant
     */
    last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeetingParticipant
     */
    usage_first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeetingParticipant
     */
    usage_last_name?: string;
    /**
     * 
     * @type {Date}
     * @memberof PatchedMeetingParticipant
     */
    readonly created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PatchedMeetingParticipant
     */
    readonly updated?: Date;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeetingParticipant
     */
    readonly meeting?: string;
    /**
     * 
     * @type {NotNestedUser}
     * @memberof PatchedMeetingParticipant
     */
    readonly user?: NotNestedUser;
    /**
     * 
     * @type {NotNestedMeetingRole}
     * @memberof PatchedMeetingParticipant
     */
    readonly role?: NotNestedMeetingRole;
    /**
     * 
     * @type {NotNestedDailyTaskTeam}
     * @memberof PatchedMeetingParticipant
     */
    readonly daily_task_team?: NotNestedDailyTaskTeam;
    /**
     * 
     * @type {Array<NotNestedTeam>}
     * @memberof PatchedMeetingParticipant
     */
    readonly teams?: Array<NotNestedTeam>;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeetingParticipant
     */
    readonly fonction?: string;
    /**
     * 
     * @type {ReadonlyStructure}
     * @memberof PatchedMeetingParticipant
     */
    readonly structure_principale?: ReadonlyStructure | null;
    /**
     * Is imported from intranet the 1st time but not synchronized after
     * 
     * * `0` - Non-défini
     * * `270` - Farfadets
     * * `210` - Louveteaux-Jeannettes
     * * `220` - Scouts-Guides
     * * `230` - Pionniers-Caravelles
     * * `240` - Compagnons
     * * `290` - Audace
     * * `180` - Impeesa
     * * `310` - Équipe Groupe
     * * `300` - Responsable Groupe
     * * `600` - Équipe Territorial
     * * `505` - Responsable Territorial
     * * `500` - Délégué Territorial
     * * `900` - Équipe National
     * * `980` - Salarié
     * * `1000` - Autre branche
     * @type {BrancheEnum}
     * @memberof PatchedMeetingParticipant
     */
    branche?: BrancheEnum;
    /**
     * 
     * @type {Track}
     * @memberof PatchedMeetingParticipant
     */
    readonly track?: Track;
    /**
     * Is imported from intranet the 1st time but not synchronized after
     * @type {string}
     * @memberof PatchedMeetingParticipant
     */
    num_bafa_bafd?: string;
    /**
     * Is imported from intranet the 1st time but not synchronized after
     * @type {string}
     * @memberof PatchedMeetingParticipant
     */
    food_regime?: string;
    /**
     * 
     * @type {SessionStatusEnum}
     * @memberof PatchedMeetingParticipant
     */
    session_status?: SessionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeetingParticipant
     */
    complement_information?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedMeetingParticipant
     */
    readonly canceled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedMeetingParticipant
     */
    hidden?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PatchedMeetingParticipant
     */
    readonly observation_count?: number;
    /**
     * 
     * @type {Date}
     * @memberof PatchedMeetingParticipant
     */
    birthdate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeetingParticipant
     */
    hebergement?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeetingParticipant
     */
    track_id?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof PatchedMeetingParticipant
     */
    readonly last_sync?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedMeetingParticipant
     */
    is_bafa_bafd_candidate?: boolean;
    /**
     * 
     * @type {TagadaCandidateStatusEnum}
     * @memberof PatchedMeetingParticipant
     */
    tagada_candidate_status?: TagadaCandidateStatusEnum;
    /**
     * 
     * @type {TagadaAvisStatusEnum}
     * @memberof PatchedMeetingParticipant
     */
    tagada_avis_status?: TagadaAvisStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof PatchedMeetingParticipant
     */
    tagada_avis_last_sync?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedMeetingParticipant
     */
    age_at_meeting_start?: number;
    /**
     * Compte rendu de début de stage
     * @type {string}
     * @memberof PatchedMeetingParticipant
     */
    start_interview?: string | null;
    /**
     * Compte rendu d'entretien de mi-stage
     * @type {string}
     * @memberof PatchedMeetingParticipant
     */
    middle_interview?: string | null;
    /**
     * Compte rendu d'entretien de fin de stage
     * @type {string}
     * @memberof PatchedMeetingParticipant
     */
    end_interview?: string | null;
    /**
     * Avis interne SGdF de fin de stage
     * @type {string}
     * @memberof PatchedMeetingParticipant
     */
    sgdf_opinion?: string | null;
    /**
     * Avis JS de fin de stage
     * @type {string}
     * @memberof PatchedMeetingParticipant
     */
    js_opinion?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeetingParticipant
     */
    readonly avis_prevalidation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeetingParticipant
     */
    readonly avis_validation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeetingParticipant
     */
    readonly avis_validation_gestionnaire?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeetingParticipant
     */
    readonly signature_url?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedMeetingParticipant
     */
    readonly signature_available?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeetingParticipant
     */
    readonly avatar_url?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedMeetingParticipant
     */
    readonly avatar_available?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeetingParticipant
     */
    readonly user_avatar_url?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedMeetingParticipant
     */
    readonly user_avatar_available?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof PatchedMeetingParticipant
     */
    user_last_sync?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeetingParticipant
     */
    gender?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeetingParticipant
     */
    birth_name?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeetingParticipant
     */
    which_administrative_lastname?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeetingParticipant
     */
    which_administrative_firstname?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeetingParticipant
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeetingParticipant
     */
    email?: string;
}



/**
 * Check if a given object implements the PatchedMeetingParticipant interface.
 */
export function instanceOfPatchedMeetingParticipant(value: object): value is PatchedMeetingParticipant {
    return true;
}

export function PatchedMeetingParticipantFromJSON(json: any): PatchedMeetingParticipant {
    return PatchedMeetingParticipantFromJSONTyped(json, false);
}

export function PatchedMeetingParticipantFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedMeetingParticipant {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'user_id': json['user_id'] == null ? undefined : json['user_id'],
        'first_name': json['first_name'] == null ? undefined : json['first_name'],
        'last_name': json['last_name'] == null ? undefined : json['last_name'],
        'usage_first_name': json['usage_first_name'] == null ? undefined : json['usage_first_name'],
        'usage_last_name': json['usage_last_name'] == null ? undefined : json['usage_last_name'],
        'created': json['created'] == null ? undefined : (new Date(json['created'])),
        'updated': json['updated'] == null ? undefined : (new Date(json['updated'])),
        'meeting': json['meeting'] == null ? undefined : json['meeting'],
        'user': json['user'] == null ? undefined : NotNestedUserFromJSON(json['user']),
        'role': json['role'] == null ? undefined : NotNestedMeetingRoleFromJSON(json['role']),
        'daily_task_team': json['daily_task_team'] == null ? undefined : NotNestedDailyTaskTeamFromJSON(json['daily_task_team']),
        'teams': json['teams'] == null ? undefined : ((json['teams'] as Array<any>).map(NotNestedTeamFromJSON)),
        'fonction': json['fonction'] == null ? undefined : json['fonction'],
        'structure_principale': json['structure_principale'] == null ? undefined : ReadonlyStructureFromJSON(json['structure_principale']),
        'branche': json['branche'] == null ? undefined : BrancheEnumFromJSON(json['branche']),
        'track': json['track'] == null ? undefined : TrackFromJSON(json['track']),
        'num_bafa_bafd': json['num_bafa_bafd'] == null ? undefined : json['num_bafa_bafd'],
        'food_regime': json['food_regime'] == null ? undefined : json['food_regime'],
        'session_status': json['session_status'] == null ? undefined : SessionStatusEnumFromJSON(json['session_status']),
        'complement_information': json['complement_information'] == null ? undefined : json['complement_information'],
        'canceled': json['canceled'] == null ? undefined : json['canceled'],
        'hidden': json['hidden'] == null ? undefined : json['hidden'],
        'observation_count': json['observation_count'] == null ? undefined : json['observation_count'],
        'birthdate': json['birthdate'] == null ? undefined : (new Date(json['birthdate'])),
        'hebergement': json['hebergement'] == null ? undefined : json['hebergement'],
        'track_id': json['track_id'] == null ? undefined : json['track_id'],
        'last_sync': json['last_sync'] == null ? undefined : (new Date(json['last_sync'])),
        'is_bafa_bafd_candidate': json['is_bafa_bafd_candidate'] == null ? undefined : json['is_bafa_bafd_candidate'],
        'tagada_candidate_status': json['tagada_candidate_status'] == null ? undefined : TagadaCandidateStatusEnumFromJSON(json['tagada_candidate_status']),
        'tagada_avis_status': json['tagada_avis_status'] == null ? undefined : TagadaAvisStatusEnumFromJSON(json['tagada_avis_status']),
        'tagada_avis_last_sync': json['tagada_avis_last_sync'] == null ? undefined : (new Date(json['tagada_avis_last_sync'])),
        'age_at_meeting_start': json['age_at_meeting_start'] == null ? undefined : json['age_at_meeting_start'],
        'start_interview': json['start_interview'] == null ? undefined : json['start_interview'],
        'middle_interview': json['middle_interview'] == null ? undefined : json['middle_interview'],
        'end_interview': json['end_interview'] == null ? undefined : json['end_interview'],
        'sgdf_opinion': json['sgdf_opinion'] == null ? undefined : json['sgdf_opinion'],
        'js_opinion': json['js_opinion'] == null ? undefined : json['js_opinion'],
        'avis_prevalidation': json['avis_prevalidation'] == null ? undefined : json['avis_prevalidation'],
        'avis_validation': json['avis_validation'] == null ? undefined : json['avis_validation'],
        'avis_validation_gestionnaire': json['avis_validation_gestionnaire'] == null ? undefined : json['avis_validation_gestionnaire'],
        'signature_url': json['signature_url'] == null ? undefined : json['signature_url'],
        'signature_available': json['signature_available'] == null ? undefined : json['signature_available'],
        'avatar_url': json['avatar_url'] == null ? undefined : json['avatar_url'],
        'avatar_available': json['avatar_available'] == null ? undefined : json['avatar_available'],
        'user_avatar_url': json['user_avatar_url'] == null ? undefined : json['user_avatar_url'],
        'user_avatar_available': json['user_avatar_available'] == null ? undefined : json['user_avatar_available'],
        'user_last_sync': json['user_last_sync'] == null ? undefined : (new Date(json['user_last_sync'])),
        'gender': json['gender'] == null ? undefined : json['gender'],
        'birth_name': json['birth_name'] == null ? undefined : json['birth_name'],
        'which_administrative_lastname': json['which_administrative_lastname'] == null ? undefined : json['which_administrative_lastname'],
        'which_administrative_firstname': json['which_administrative_firstname'] == null ? undefined : json['which_administrative_firstname'],
        'phone': json['phone'] == null ? undefined : json['phone'],
        'email': json['email'] == null ? undefined : json['email'],
    };
}

export function PatchedMeetingParticipantToJSON(json: any): PatchedMeetingParticipant {
    return PatchedMeetingParticipantToJSONTyped(json, false);
}

export function PatchedMeetingParticipantToJSONTyped(value?: Omit<PatchedMeetingParticipant, 'id'|'user_id'|'created'|'updated'|'meeting'|'user'|'role'|'daily_task_team'|'teams'|'fonction'|'structure_principale'|'track'|'canceled'|'observation_count'|'last_sync'|'avis_prevalidation'|'avis_validation'|'avis_validation_gestionnaire'|'signature_url'|'signature_available'|'avatar_url'|'avatar_available'|'user_avatar_url'|'user_avatar_available'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'first_name': value['first_name'],
        'last_name': value['last_name'],
        'usage_first_name': value['usage_first_name'],
        'usage_last_name': value['usage_last_name'],
        'branche': BrancheEnumToJSON(value['branche']),
        'num_bafa_bafd': value['num_bafa_bafd'],
        'food_regime': value['food_regime'],
        'session_status': SessionStatusEnumToJSON(value['session_status']),
        'complement_information': value['complement_information'],
        'hidden': value['hidden'],
        'birthdate': value['birthdate'] == null ? undefined : ((value['birthdate'] as any).toISOString().substring(0,10)),
        'hebergement': value['hebergement'],
        'track_id': value['track_id'],
        'is_bafa_bafd_candidate': value['is_bafa_bafd_candidate'],
        'tagada_candidate_status': TagadaCandidateStatusEnumToJSON(value['tagada_candidate_status']),
        'tagada_avis_status': TagadaAvisStatusEnumToJSON(value['tagada_avis_status']),
        'tagada_avis_last_sync': value['tagada_avis_last_sync'] == null ? undefined : ((value['tagada_avis_last_sync'] as any).toISOString()),
        'age_at_meeting_start': value['age_at_meeting_start'],
        'start_interview': value['start_interview'],
        'middle_interview': value['middle_interview'],
        'end_interview': value['end_interview'],
        'sgdf_opinion': value['sgdf_opinion'],
        'js_opinion': value['js_opinion'],
        'user_last_sync': value['user_last_sync'] == null ? undefined : ((value['user_last_sync'] as any).toISOString()),
        'gender': value['gender'],
        'birth_name': value['birth_name'],
        'which_administrative_lastname': value['which_administrative_lastname'],
        'which_administrative_firstname': value['which_administrative_firstname'],
        'phone': value['phone'],
        'email': value['email'],
    };
}

