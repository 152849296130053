/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Djoser
 */
export interface Djoser {
    /**
     * url to keycloak
     * @type {string}
     * @memberof Djoser
     */
    autorization_url: string;
}

/**
 * Check if a given object implements the Djoser interface.
 */
export function instanceOfDjoser(value: object): value is Djoser {
    if (!('autorization_url' in value) || value['autorization_url'] === undefined) return false;
    return true;
}

export function DjoserFromJSON(json: any): Djoser {
    return DjoserFromJSONTyped(json, false);
}

export function DjoserFromJSONTyped(json: any, ignoreDiscriminator: boolean): Djoser {
    if (json == null) {
        return json;
    }
    return {
        
        'autorization_url': json['autorization_url'],
    };
}

export function DjoserToJSON(json: any): Djoser {
    return DjoserToJSONTyped(json, false);
}

export function DjoserToJSONTyped(value?: Djoser | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'autorization_url': value['autorization_url'],
    };
}

