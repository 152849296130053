/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `619` - AFPS
 * * `73` - BAFA
 * * `140` - BAFD
 * * `618` - BP JEPS
 * * `615` - Diplome Marin
 * * `1000` - Équivalence BAFD
 * * `632` - DE Educateur Spécialisé 
 * * `626` - DE JEPS
 * * `603` - PSC1 Prevention et Secours Civiques de niveau 1
 * * `608` - Surveillant de Baignade
 * * `1001` - Autre Diplome ou Brevet
 * @export
 */
export const DiplomaTypeEnum = {
    _619: '619',
    _73: '73',
    _140: '140',
    _618: '618',
    _615: '615',
    _1000: '1000',
    _632: '632',
    _626: '626',
    _603: '603',
    _608: '608',
    _1001: '1001'
} as const;
export type DiplomaTypeEnum = typeof DiplomaTypeEnum[keyof typeof DiplomaTypeEnum];


export function instanceOfDiplomaTypeEnum(value: any): boolean {
    for (const key in DiplomaTypeEnum) {
        if (Object.prototype.hasOwnProperty.call(DiplomaTypeEnum, key)) {
            if (DiplomaTypeEnum[key as keyof typeof DiplomaTypeEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function DiplomaTypeEnumFromJSON(json: any): DiplomaTypeEnum {
    return DiplomaTypeEnumFromJSONTyped(json, false);
}

export function DiplomaTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiplomaTypeEnum {
    return json as DiplomaTypeEnum;
}

export function DiplomaTypeEnumToJSON(value?: DiplomaTypeEnum | null): any {
    return value as any;
}

export function DiplomaTypeEnumToJSONTyped(value: any, ignoreDiscriminator: boolean): DiplomaTypeEnum {
    return value as DiplomaTypeEnum;
}

