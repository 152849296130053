/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface PatchedTeamDailyTask
 */
export interface PatchedTeamDailyTask {
    /**
     * 
     * @type {string}
     * @memberof PatchedTeamDailyTask
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedTeamDailyTask
     */
    daily_task?: string;
    /**
     * 
     * @type {Date}
     * @memberof PatchedTeamDailyTask
     */
    date?: Date;
    /**
     * 
     * @type {string}
     * @memberof PatchedTeamDailyTask
     */
    team_in_charge?: string;
}

/**
 * Check if a given object implements the PatchedTeamDailyTask interface.
 */
export function instanceOfPatchedTeamDailyTask(value: object): value is PatchedTeamDailyTask {
    return true;
}

export function PatchedTeamDailyTaskFromJSON(json: any): PatchedTeamDailyTask {
    return PatchedTeamDailyTaskFromJSONTyped(json, false);
}

export function PatchedTeamDailyTaskFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedTeamDailyTask {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'daily_task': json['daily_task'] == null ? undefined : json['daily_task'],
        'date': json['date'] == null ? undefined : (new Date(json['date'])),
        'team_in_charge': json['team_in_charge'] == null ? undefined : json['team_in_charge'],
    };
}

export function PatchedTeamDailyTaskToJSON(json: any): PatchedTeamDailyTask {
    return PatchedTeamDailyTaskToJSONTyped(json, false);
}

export function PatchedTeamDailyTaskToJSONTyped(value?: Omit<PatchedTeamDailyTask, 'id'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'daily_task': value['daily_task'],
        'date': value['date'] == null ? undefined : ((value['date']).toISOString().substring(0,10)),
        'team_in_charge': value['team_in_charge'],
    };
}

